import {auth} from './auth'

export const clientName = 'MusicTagWeb'

export function normalizeAlbum(item) {
    return {
        id: item.id,
        name: item.name,
        artist: item.artist,
        otherArtist: item.other_artist,
        artistId: item.artistId,
        image: getCoverArtUrl(item),
        year: item.year || 0,
        favourite: !!item.starred,
        genreId: item.genre,
        duration: item.duration,
        created: item.created,
        comment: item.comment || '',
        tracks: (item.song || []).map(normalizeTrack, this)
    }
}

export function normalizeArtist(item) {
    const albums = (item.album || [])
        .map(normalizeAlbum, this)
        .sort((a, b) => b.year - a.year)

    return {
        id: item.id,
        name: item.name,
        description: (item.biography || '').replace(/<a[^>]*>.*?<\/a>/gm, ''),
        genres: (item.album || []).flatMap((album) => album.genre || []),
        albumCount: item.albumCount,
        trackCount: (item.album || []).reduce((acc, album) => acc + (album.songCount || 0), 0),
        favourite: !!item.starred,
        lastFmUrl: item.lastFmUrl,
        musicBrainzUrl: item.musicBrainzId
            ? `https://musicbrainz.org/artist/${item.musicBrainzId}`
            : undefined,
        albums,
        similarArtist: (item.similarArtist || []).map(normalizeArtist, this),
        topTracks: (item.topSongs || []).map(normalizeTrack, this),
        image: item.coverArt ? getCoverArtUrl(item) : item.artistImageUrl
    }
}

export function normalizeTrack(item) {
    return {
        id: item.id,
        title: item.title,
        duration: item.duration,
        favourite: !!item.starred,
        track: item.track,
        album: item.album,
        albumId: item.albumId,
        artist: item.artist,
        artistId: item.artistId,
        path: item.path,
        size: item.size,
        bitrate: item.bitrate,
        url: getStreamUrl(item.id),
        image: getCoverArtUrl(item),
        fromFolder: item.from_folder || '',
        playsCount: item.plays_count || 0,
    }
}
export function normalizeMiTrack(item) {
    return {
        id: item.id,
        title: item.title,
        duration: item.duration,
        favourite: !!item.starred,
        track: item.track,
        album: item.album,
        albumId: item.albumId,
        artist: item.artist,
        artistId: item.artistId,
        path: item.path,
        size: item.size,
        bitrate: item.bitrate,
        url: getStreamUrl(item.id),
        image: getCoverArtUrl(item),
        fromFolder: item.from_folder || '',
        playsCount: item.plays_count || 0,
        index: item.index || 0,
        status: item.status || '',
        lyrics: item.lyrics || '',
        current_time: item.current_time || 0
    }
}
export function normalizePlaylist(response) {
    return {
        id: response.id,
        name: response.name || '(Unnamed)',
        comment: response.comment || '',
        createdAt: response.created || '',
        owner: response.owner || '',
        updatedAt: response.changed || '',
        trackCount: response.songCount,
        image: getCoverArtUrl(response),
        isDynamic: response.is_dynamic || false,
        privacyLevel: response.privacy_level || ''
    }
}
export function getCoverArtUrl(item) {
    if (!item.coverArt) {
        return undefined
    }
    const {server, username, salt, hash} = auth
    return `${server}/rest/getCoverArt` +
        `?id=${item.coverArt}` +
        '&v=1.16.0' +
        `&u=${username}` +
        `&s=${salt}` +
        `&t=${hash}` +
        `&c=${clientName}` +
        '&size=300'
}

function getStreamUrl(id) {
    const {server, username, salt, hash} = auth
    return `${server}/rest/stream` +
        `?id=${id}` +
        '&v=1.16.0' +
        `&u=${username}` +
        `&s=${salt}` +
        `&t=${hash}` +
        `&c=${clientName}`
}
