<template>
    <div>
        <div role="tablist" class="tabs tabs-boxed bg-base-100 mx-2 mt-2 shadow-sm rounded-xl !flex">
            <a role="tab" class="tab tab-active">刮削报告</a>
        </div>
        <div>
            <div class="flex flex-col md:flex-row">
                <div
                    class="bg-base-100 md:w-1/2 md:h-60 shadow-xl rounded-xl m-2 flex flex-col md:flex-row md:justify-between">
                    <div class="p-8">
                        <div class="mt-4 indent-4 text-gray-500">
                            <div class="flex mb-2">
                                <div class="font-medium text-md">标题:</div>
                                <div>{{ detailData.name }}</div>
                            </div>
                            <div class="flex mb-2">
                                <div class="font-medium text-md">创建时间:</div>
                                <div>{{ detailData.created_at }}</div>
                            </div>
                            <div class="flex mb-2">
                                <div class="font-medium text-md">执行状态:</div>
                                <div>{{ detailData.status_display }}</div>
                                <div v-if="detailData.status === 'running'">
                                    <button class="btn btn-xs btn-outline btn-error" @click="handleStop">停止</button>
                                </div>
                            </div>
                            <div class="flex mb-2">
                                <div class="font-medium text-md">运行时间:</div>
                                <div>{{ detailData.run_time }}</div>
                            </div>
                            <div class="flex mb-2">
                                <div class="font-medium text-md">结束时间:</div>
                                <div>{{ detailData.complete_at }}</div>
                            </div>
                        </div>
                    </div>
                    <img src="/static/dist/img/play_music.svg" alt="Album" class="h-64 z-1"/>
                </div>
                <div class="m-2 flex flex-col grow">
                    <div class="stats stats-vertical md:stats-horizontal shadow-xl">
                        <div class="stat">
                            <div class="stat-figure text-primary">
                                <svg-icon 
                                    name="check"
                                    class="fill-secondary"
                                    width="32"
                                    height="32"
                                />
                            </div>
                            <div class="stat-title">已完成</div>
                            <div class="stat-value text-primary cursor-pointer">{{ detailData.static.complete_count }}</div>
                            <div class="stat-desc">包括成功失败和未匹配</div>
                        </div>

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg-icon 
                                    name="document"
                                    class="fill-primary"
                                    width="32"
                                    height="32"
                                />
                            </div>
                            <div class="stat-title">总数</div>
                            <div class="stat-value text-secondary">{{ detailData.static.total_count }}</div>
                            <div class="stat-desc">总共个数</div>
                        </div>

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <div class="radial-progress text-primary"
                                     :style="`--value: ${detailData.static.complete_rate};`" role="progressbar">
                                    {{ detailData.static.complete_rate }}%
                                </div>
                            </div>
                            <div class="stat-value">{{ detailData.static.complete_rate }}</div>
                            <div class="stat-title">完成进度</div>
                            <div class="stat-desc text-secondary">完成进度百分比</div>
                        </div>
                    </div>
                    <div class="stats stats-vertical md:stats-horizontal shadow-xl mt-4">

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg-icon 
                                    name="check"
                                    class="fill-primary"
                                    width="32"
                                    height="32"
                                />
                            </div>
                            <div class="stat-title">成功</div>
                            <div class="stat-value text-secondary cursor-pointer">
                                {{ detailData.static.success_count }}
                            </div>
                            <div class="stat-desc">成功完成并匹配上的任务</div>
                        </div>

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg-icon 
                                    name="close"
                                    class="fill-secondary"
                                    width="32"
                                    height="32"
                                />
                            </div>
                            <div class="stat-title">失败</div>
                            <div class="stat-value text-primary cursor-pointer">{{ detailData.static.fail_count }}</div>
                            <div class="stat-desc">文件异常或网络异常的任务</div>
                        </div>

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg-icon 
                                    name="minus"
                                    width="32"
                                    height="32"
                                />
                            </div>
                            <div class="stat-title">未匹配</div>
                            <div class="stat-value">{{ detailData.static.miss_count }}</div>
                            <div class="stat-desc">元数据匹配不正确的</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div v-if="detailData.name === '重复检查'">
            <duplicate-log-detail :detail-data="detailData"></duplicate-log-detail>
        </div>
        <div v-else-if="detailData.name === '导入收藏'">
            <import-music-detail :detail-data="detailData"></import-music-detail>
        </div>
        <div class="pt-2 mx-8" v-else>
            <div class="p-4 rounded-xl bg-base-100 mb-2" v-if="detailData.running.length > 0">
                <div class=" text-xl font-medium">
                    <div class="flex items-center">
                        <div>进行中</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{ detailData.running_count }}
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th></th>
                                <th>文件名</th>
                                <th>创建时间</th>
                                <th>原因</th>
                                <th class="w-32">操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <!-- row 1 -->
                            <tr v-for="(item, index) in paginatedData.running" :key="'running' + index">
                                <th>{{ (pagination.running.current - 1) * pagination.running.pageSize + index + 1 }}</th>
                                <td>{{ item.name }}</td>
                                <td>{{ item.created_at }}</td>
                                <td>{{ item.reason }}</td>
                                <td>
                                    <button class="btn btn-xs btn-outline btn-error" @click="handleStopDetail(item.id)">停止</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="flex justify-center mt-4" v-if="detailData.running.length > pagination.running.pageSize">
                            <div class="join">
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.running.current === 1}"
                                    @click="handlePageChange('running', pagination.running.current - 1)">«</button>
                                <button class="join-item btn btn-sm">第 {{ pagination.running.current }} 页</button>
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.running.current >= Math.ceil(detailData.running.length / pagination.running.pageSize)}"
                                    @click="handlePageChange('running', pagination.running.current + 1)">»</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-base-100 mb-2 p-4 rounded-xl" v-if="detailData.miss_tag.length > 0">
                <div class="text-xl font-medium">
                    <div class="flex items-center">
                        <div>未匹配到标签</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{ detailData.miss_tag_count }}
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th></th>
                                <th>文件名</th>
                                <th>原因</th>
                                <th class="w-32">操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <!-- row 1 -->
                            <tr v-for="(item, index) in paginatedData.missTag" :key="'miss_tag' + index">
                                <th>{{ (pagination.missTag.current - 1) * pagination.missTag.pageSize + index + 1 }}</th>
                                <td>{{ item.name }}</td>
                                <td>{{ item.reason }}</td>
                                <td>
                                    <a class="link link-secondary" v-if="item.candidate_list.length >0"
                                       @click="handleBeixuan(item)">候选
                                    </a>
                                    <a class="link link-primary" v-else
                                       @click="handleEditDetail(item)">编辑
                                    </a>
                                    <a class="link link-success"
                                       @click="handleFinish(item)">完成
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="flex justify-center mt-4" v-if="detailData.miss_tag.length > pagination.missTag.pageSize">
                            <div class="join">
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.missTag.current === 1}"
                                    @click="handlePageChange('missTag', pagination.missTag.current - 1)">«</button>
                                <button class="join-item btn btn-sm">第 {{ pagination.missTag.current }} 页</button>
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.missTag.current >= Math.ceil(detailData.miss_tag.length / pagination.missTag.pageSize)}"
                                    @click="handlePageChange('missTag', pagination.missTag.current + 1)">»</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-4 rounded-xl bg-base-100 mb-2" v-if="detailData.miss_artist.length > 0">
                <div class=" text-xl font-medium">
                    <div class="flex items-center">
                        <div>缺失艺术家</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{ detailData.miss_artist_count }}
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th></th>
                                <th>文件名</th>
                                <th>标题</th>
                                <th>艺术家</th>
                                <th>专辑</th>
                                <th>原因</th>
                            </tr>
                            </thead>
                            <tbody>
                            <!-- miss artist -->
                            <tr v-for="(item, index) in paginatedData.missArtist" :key="'miss_artist' + index">
                                <th>{{ (pagination.missArtist.current - 1) * pagination.missArtist.pageSize + index + 1 }}</th>
                                <td>{{ item.name }}</td>
                                <td>{{ item.title }}</td>
                                <td>{{ item.artist }}</td>
                                <td>{{ item.album }}</td>
                                <td>{{ item.reason }}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="flex justify-center mt-4" v-if="detailData.miss_artist.length > pagination.missArtist.pageSize">
                            <div class="join">
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.missArtist.current === 1}"
                                    @click="handlePageChange('missArtist', pagination.missArtist.current - 1)">«</button>
                                <button class="join-item btn btn-sm">第 {{ pagination.missArtist.current }} 页</button>
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.missArtist.current >= Math.ceil(detailData.miss_artist.length / pagination.missArtist.pageSize)}"
                                    @click="handlePageChange('missArtist', pagination.missArtist.current + 1)">»</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-4 rounded-xl bg-base-100 mb-2" v-if="detailData.miss_album.length > 0">
                <div class=" text-xl font-medium">
                    <div class="flex items-center">
                        <div>缺失专辑</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{ detailData.miss_album_count }}
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th></th>
                                <th>文件名</th>
                                <th>标题</th>
                                <th>艺术家</th>
                                <th>专辑</th>
                                <th>原因</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in paginatedData.missAlbum" :key="'miss_album' + index">
                                <th>{{ (pagination.missAlbum.current - 1) * pagination.missAlbum.pageSize + index + 1 }}</th>
                                <td>{{ item.name }}</td>
                                <td>{{ item.title }}</td>
                                <td>{{ item.artist }}</td>
                                <td>{{ item.album }}</td>
                                <td>{{ item.reason }}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="flex justify-center mt-4" v-if="detailData.miss_album.length > pagination.missAlbum.pageSize">
                            <div class="join">
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.missAlbum.current === 1}"
                                    @click="handlePageChange('missAlbum', pagination.missAlbum.current - 1)">«</button>
                                <button class="join-item btn btn-sm">第 {{ pagination.missAlbum.current }} 页</button>
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.missAlbum.current >= Math.ceil(detailData.miss_album.length / pagination.missAlbum.pageSize)}"
                                    @click="handlePageChange('missAlbum', pagination.missAlbum.current + 1)">»</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-4 rounded-xl bg-base-100 mb-2" v-if="detailData.miss_artist_album.length > 0">
                <div class=" text-xl font-medium">
                    <div class="flex items-center">
                        <div>缺失艺术家和专辑</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{ detailData.miss_artist_album_count }}
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th></th>
                                <th>文件名</th>
                                <th>原因</th>
                            </tr>
                            </thead>
                            <tbody>
                            <!-- miss artist album -->
                            <tr v-for="(item, index) in paginatedData.missArtistAlbum" :key="'miss_artist_album' + index">
                                <th>{{ (pagination.missArtistAlbum.current - 1) * pagination.missArtistAlbum.pageSize + index + 1 }}</th>
                                <td>{{ item.name }}</td>
                                <td>{{ item.reason }}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="flex justify-center mt-4" v-if="detailData.miss_artist_album.length > pagination.missArtistAlbum.pageSize">
                            <div class="join">
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.missArtistAlbum.current === 1}"
                                    @click="handlePageChange('missArtistAlbum', pagination.missArtistAlbum.current - 1)">«</button>
                                <button class="join-item btn btn-sm">第 {{ pagination.missArtistAlbum.current }} 页</button>
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.missArtistAlbum.current >= Math.ceil(detailData.miss_artist_album.length / pagination.missArtistAlbum.pageSize)}"
                                    @click="handlePageChange('missArtistAlbum', pagination.missArtistAlbum.current + 1)">»</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-4 rounded-xl bg-base-100 mb-2" v-if="detailData.fail.length > 0">
                <div class=" text-xl font-medium">
                    <div class="flex items-center">
                        <div>异常失败</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{ detailData.fail_count }}
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th></th>
                                <th>文件名</th>
                                <th>标题</th>
                                <th>艺术家</th>
                                <th>专辑</th>
                                <th>原因</th>
                                <th class="w-16">操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <!-- row 1 -->
                            <tr v-for="(item, index) in paginatedData.fail" :key="'fail' + index">
                                <th>{{ (pagination.fail.current - 1) * pagination.fail.pageSize + index + 1 }}</th>
                                <td>{{ item.name }}</td>
                                <td>{{ item.title }}</td>
                                <td>{{ item.artist }}</td>
                                <td>{{ item.album }}</td>
                                <td>{{ item.reason }}</td>
                                <td>
                                    <a class="link link-secondary" v-if="item.candidate_list.length >0"
                                       @click="handleBeixuan(item)">候选</a>
                                    <a class="link"
                                       @click="handleDelete(item)">删除</a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="flex justify-center mt-4" v-if="detailData.fail.length > pagination.fail.pageSize">
                            <div class="join">
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.fail.current === 1}"
                                    @click="handlePageChange('fail', pagination.fail.current - 1)">«</button>
                                <button class="join-item btn btn-sm">第 {{ pagination.fail.current }} 页</button>
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.fail.current >= Math.ceil(detailData.fail.length / pagination.fail.pageSize)}"
                                    @click="handlePageChange('fail', pagination.fail.current + 1)">»</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-4 rounded-xl bg-base-100 mb-2" v-if="detailData.mode_success.length > 0">
                <div class=" text-xl font-medium">
                    <div class="flex items-center">
                        <div>{{ detailData.mode_name }}</div>
                        <div class="badge badge-success gap-2 ml-2">
                            {{ detailData.mode_success_count }}
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th></th>
                                <th>文件名</th>
                                <th>标题</th>
                                <th>艺术家</th>
                                <th>专辑</th>
                                <th>原因</th>
                                <th class="w-16">操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <!-- row 1 -->
                            <tr v-for="(item, index) in paginatedData.modeSuccess" :key="'mode' + index">
                                <th>{{ (pagination.modeSuccess.current - 1) * pagination.modeSuccess.pageSize + index + 1 }}</th>
                                <td>{{ item.name }}</td>
                                <td>{{ item.title }}</td>
                                <td>{{ item.artist }}</td>
                                <td>{{ item.album }}</td>
                                <td>{{ item.reason }}</td>
                                <td>
                                    <a class="link link-secondary" v-if="item.candidate_list.length >0"
                                       @click="handleBeixuan(item)">复核</a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="flex justify-center mt-4" v-if="detailData.mode_success.length > pagination.modeSuccess.pageSize">
                            <div class="join">
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.modeSuccess.current === 1}"
                                    @click="handlePageChange('modeSuccess', pagination.modeSuccess.current - 1)">«</button>
                                <button class="join-item btn btn-sm">第 {{ pagination.modeSuccess.current }} 页</button>
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.modeSuccess.current >= Math.ceil(detailData.mode_success.length / pagination.modeSuccess.pageSize)}"
                                    @click="handlePageChange('modeSuccess', pagination.modeSuccess.current + 1)">»</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-4 rounded-xl bg-base-100 mb-2" v-if="detailData.success.length > 0">
                <div class=" text-xl font-medium">
                    <div class="flex items-center">
                        <div>完全匹配</div>
                        <div class="badge badge-success gap-2 ml-2">
                            {{ detailData.success_count }}
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th></th>
                                <th>文件名</th>
                                <th>标题</th>
                                <th>艺术家</th>
                                <th>专辑</th>
                            </tr>
                            </thead>
                            <tbody>
                            <!-- row 1 -->
                            <tr v-for="(item, index) in paginatedData.success" :key="'succ' + index">
                                <th>{{ (pagination.success.current - 1) * pagination.success.pageSize + index + 1 }}</th>
                                <td>{{ item.name }}</td>
                                <td>{{ item.title }}</td>
                                <td>{{ item.artist }}</td>
                                <td>{{ item.album }}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="flex justify-center mt-4" v-if="detailData.success.length > pagination.success.pageSize">
                            <div class="join">
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.success.current === 1}"
                                    @click="handlePageChange('success', pagination.success.current - 1)">«</button>
                                <button class="join-item btn btn-sm">第 {{ pagination.success.current }} 页</button>
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.success.current >= Math.ceil(detailData.success.length / pagination.success.pageSize)}"
                                    @click="handlePageChange('success', pagination.success.current + 1)">»</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-4 rounded-xl bg-base-100 mb-2" v-if="detailData.skip.length > 0">
                <div class=" text-xl font-medium">
                    <div class="flex items-center">
                        <div>跳过匹配</div>
                        <div class="badge badge-neutral gap-2 ml-2">
                            {{ detailData.skip_count }}
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th></th>
                                <th>文件名</th>
                                <th>原因</th>
                            </tr>
                            </thead>
                            <tbody>
                            <!-- row 1 -->
                            <tr v-for="(item, index) in paginatedData.skip" :key="'skip' + index">
                                <th>{{ (pagination.skip.current - 1) * pagination.skip.pageSize + index + 1 }}</th>
                                <td>{{ item.name }}</td>
                                <td>已存在标签,无需再刮削</td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="flex justify-center mt-4" v-if="detailData.skip.length > pagination.skip.pageSize">
                            <div class="join">
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.skip.current === 1}"
                                    @click="handlePageChange('skip', pagination.skip.current - 1)">«</button>
                                <button class="join-item btn btn-sm">第 {{ pagination.skip.current }} 页</button>
                                <button class="join-item btn btn-sm" 
                                    :class="{'btn-disabled': pagination.skip.current >= Math.ceil(detailData.skip.length / pagination.skip.pageSize)}"
                                    @click="handlePageChange('skip', pagination.skip.current + 1)">»</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="drawer">
            <input id="id3-detail" type="checkbox" class="drawer-toggle" v-model="isShowResourceId32"/>
            <div class="drawer-content">
            </div>
            <div class="drawer-side">
                <label for="id3-detail" aria-label="close sidebar" class="drawer-overlay"></label>
                <div class="p-4 min-h-full bg-base-100 text-base-content" style="width: 400px;">
                    <div>
                        <music-meta-data :music-info="musicInfo" :reload-img="reloadImg" :drawer-mode="'drawer'">
                        </music-meta-data>
                    </div>
                </div>
            </div>
        </div>
        <div class="drawer drawer-end">
            <input id="resource-id3-detail" type="checkbox" class="drawer-toggle" v-model="isShowResourceId3"/>
            <div class="drawer-content">
            </div>
            <div class="drawer-side">
                <label for="resource-id3-detail" aria-label="close sidebar" class="drawer-overlay"></label>
                <div class="p-4 min-h-full bg-base-100 text-base-content" style="width: 800px;">
                    <resource :song-list="selectSongList" :section3-loading="section3Loading"></resource>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import duplicateLogDetail from "@/views/log/duplicateLogDetail";
import importMusicDetail from "@/views/log/importMusicDetail";
import Resource from '@/views/home/resource'
import MusicMetaData from '@/views/home/musicMetaData'
import SvgIcon from '@/components/SvgIcon.vue'

export default {
    name: 'log-detail',
    components: {
        duplicateLogDetail,
        importMusicDetail,
        Resource,
        MusicMetaData,
        SvgIcon
    },
    data() {
        return {
            id: this.$route.params.id,
            detailData: {
                'success': [],
                'fail': [],
                'mode_success': [],
                'miss_artist_album': [],
                'miss_artist': [],
                'miss_album': [],
                'miss_tag': [],
                'static': {
                    'complete_count': 0
                }
            },
            pagination: {
                running: { current: 1, pageSize: 10 },
                missTag: { current: 1, pageSize: 10 },
                missArtist: { current: 1, pageSize: 10 },
                missAlbum: { current: 1, pageSize: 10 },
                missArtistAlbum: { current: 1, pageSize: 10 },
                fail: { current: 1, pageSize: 10 },
                modeSuccess: { current: 1, pageSize: 10 },
                success: { current: 1, pageSize: 10 },
                skip: { current: 1, pageSize: 10 }
            },
            isOpen: true,
            timer: null,
            isShowResourceId3: false,
            isShowResourceId32: false,
            section3Loading: false,
            selectSongList: [],
            reloadImg: true,
            musicInfo: {},
            fullPath: '',
            currentDetailId: ''
        }
    },
    computed: {
        isSuccess: {
            get() {
                return this.detailData.success.length > 0
            },
            set() {}
        },
        isFail: {
            get() {
                return this.detailData.fail.length > 0
            },
            set(){}
        },
        isModeSuccess: {
            get() {
                return this.detailData.mode_success.length > 0
            },
            set(){}
        },
        isMissAA() {
            return this.detailData.miss_artist_album.length > 0
        },
        isMissArtist() {
            return this.detailData.miss_artist.length > 0
        },
        isMissAlbum() {
            return this.detailData.miss_album.length > 0
        },
        isMissTag() {
            return this.detailData.miss_tag.length > 0
        },
        isRunning() {
            return this.detailData.running.length > 0
        },
        isSkip() {
            return this.detailData.skip.length > 0
        },
        paginatedData() {
            return {
                running: this.getPaginatedData(this.detailData.running || [], this.pagination.running),
                missTag: this.getPaginatedData(this.detailData.miss_tag || [], this.pagination.missTag),
                missArtist: this.getPaginatedData(this.detailData.miss_artist || [], this.pagination.missArtist),
                missAlbum: this.getPaginatedData(this.detailData.miss_album || [], this.pagination.missAlbum),
                missArtistAlbum: this.getPaginatedData(this.detailData.miss_artist_album || [], this.pagination.missArtistAlbum),
                fail: this.getPaginatedData(this.detailData.fail || [], this.pagination.fail),
                modeSuccess: this.getPaginatedData(this.detailData.mode_success || [], this.pagination.modeSuccess),
                success: this.getPaginatedData(this.detailData.success || [], this.pagination.success),
                skip: this.getPaginatedData(this.detailData.skip || [], this.pagination.skip)
            }
        }
    },
    mounted() {
        this.handlePolling()
    },
    beforeDestroy() {
        this.setTimerNull() // 组件销毁前清空定时器
    },
    methods: {
        fetchDetails() {
            this.$api.Task.getAuditLogDetail({id: this.id}).then((res) => {
                if (res.result) {
                    this.detailData = res.data
                }
            })
        },
        // 轮询开启
        handlePolling() {
            // 查询接口
            this.fetchDetails()
            this.timer = setInterval(() => {
                // console.log('轮询', this.detailData.status, this.detailData.status.indexOf(['success', 'fail', 'miss', 'stop']))
                if (['success', 'fail', 'miss', 'stop'].indexOf(this.detailData.status) !== -1) {
                    this.setTimerNull()
                } else {
                    this.fetchDetails()
                }
            }, 3000)
        },
        setTimerNull() {
            clearInterval(this.timer)
            this.timer = null
        },
        handleStop() {
            this.$api.Task.stopAuditLogDetail({id: this.id}).then((res) => {
                if (res.result) {
                    this.$myMsg.notify({
                            title: '停止成功！',
                            type: 'success',
                            time: 2000,
                        })
                    this.fetchDetails()
                } else {
                    this.$myMsg.notify({
                        title: '停止失败！',
                        content: res.message,
                        type: 'error',
                        time: 2000,
                    })
                }
            })
        },
        handleStopDetail(id) {
            this.$api.Task.stopAuditLogDetailRun({id: id}).then((res) => {
                if (res.result) {
                    this.$myMsg.notify({
                            title: '停止成功！',
                            type: 'success',
                            time: 2000,
                        })
                    this.fetchDetails()
                } else {
                    this.$myMsg.notify({
                        title: '停止失败！',
                        content: res.message,
                        type: 'error',
                        time: 2000,
                    })
                }
            })
        },
        handleBeixuan(item) {
            this.currentDetailId = item.id
            this.fullPath = item.path
            this.isShowResourceId32 = true
            this.isShowResourceId3 = true
            this.selectSongList = item.candidate_list
            const parentPath = item.path.replace(item.name, '')
            this.$api.Task.musicId3({'file_path': parentPath, 'file_name': item.name}).then((res) => {
                if(res.result) {
                    this.musicInfo = res.data
                }
            })
        },
        handleEditDetail(item) {
            this.fullPath = item.path
            this.isShowResourceId32 = true
            const parentPath = item.path.replace(item.name, '')
            this.$api.Task.musicId3({'file_path': parentPath, 'file_name': item.name}).then((res) => {
                if(res.result) {
                    this.musicInfo = res.data
                }
            })
        },
        handleCopy(k, v) {
            if (k === 'lyric') {
                this.resource = localStorage.getItem('resource') ? localStorage.getItem('resource') : 'netease'
                const resurce = this.resource !== 'smart_tag' ? this.resource : v.resource
                this.$api.Task.fetchLyric({'song_id': v.id, 'resource': resurce}).then((res) => {
                    if (res.result) {
                        if (res.data !== '') {
                            this.musicInfo['lyrics'] = res.data
                            this.handleCopy('composer', this.musicInfo['lyrics'])
                        } else {
                            this.$myMsg.notify({
                                title: '未找到歌词！',
                                type: 'error',
                                time: 2000,
                            })
                        }
                    } else {
                        this.$myMsg.notify({
                            title: '未找到歌词！',
                            type: 'error',
                            time: 2000,
                        })
                    }
                })
            } else if (k === 'composer') {
                this.$api.Task.extractLyric({'lyc': v}).then((res) => {
                    if (res.result) {
                        if (res.data !== '') {
                            this.musicInfo['composer'] = res.data.composer
                            this.musicInfo['lyricist'] = res.data.lyricist
                        } else {
                            console.log()
                        }
                    } else {
                        console.log()
                    }
                })
            }
            else if (k === 'album_img') {
                this.musicInfo[k] = ''
                this.musicInfo['artwork'] = v
                this.musicInfo['files1'] = [
                    {
                        name: 'new.png',
                        status: 'done',
                        url: v
                    }
                ]
                this.reloadImg = false
                this.$nextTick(() => {
                    this.reloadImg = true
                })
            } else if (k === 'lyric_tran') {
                this.musicInfo['lyrics'] = v
            } else {
                this.musicInfo[k] = v
            }
        },
        copyAll(item) {
            this.handleCopy('title', item.name)
            this.handleCopy('year', item.year)
            this.handleCopy('lyric', item)
            this.handleCopy('album', item.album)
            this.handleCopy('artist', item.artist)
            this.handleCopy('album_img', item.album_img)
            this.handleCopy('tracknumber', item.tracknumber)
            this.handleCopy('discnumber', item.discnumber)
            this.handleCopy('totaltracks', item.totaltracks)
            this.handleCopy('totaldiscs', item.totaldiscs)
        },
        handleClick() {
            this.$api.Task.changeStatusAuditLogDetailRun({id: this.currentDetailId}).then((res) =>{
                if (res.result) {
                    this.fetchDetails()
                }
            })
        },
        toggleLock() {
            this.isShowResourceId3 = true
            this.section3Loading = true
            this.$api.Task.fetchId3Title({
                title: this.musicInfo.title,
                artist: this.musicInfo.artist,
                album: this.musicInfo.album,
                full_path: this.fullPath,
                resource: localStorage.getItem('resource') ? localStorage.getItem('resource') : 'netease'
            }).then((res) => {
                this.selectSongList = res.data
                this.section3Loading = false
            })
        },
        handleDelete(item) {
            this.$api.Task.deleteFiles({'log_id': this.id, 'mode': 'single', 'paths': [], 'detail_id': item.id}).then((res) => {
                if (res.result) {
                    this.$myMsg.notify({
                        title: '删除成功！',
                        type: 'success',
                        time: 2000,
                    })
                }
            })
        },
        handleFinish(item) {
            this.$api.Task.finishFiles({'log_id': this.id, 'mode': 'single', 'paths': [], 'detail_id': item.id}).then((res) => {
                if (res.result) {
                    this.$myMsg.notify({
                        title: '完成成功！',
                        type: 'success',
                        time: 2000,
                    })
                    this.fetchDetails()
                }
            })
        },
        getPaginatedData(data, pagination) {
            const start = (pagination.current - 1) * pagination.pageSize;
            const end = start + pagination.pageSize;
            return data.slice(start, end);
        },
        handlePageChange(type, page) {
            this.pagination[type].current = page;
        }
    }
}
</script>

<style scoped>

</style>
