<template>
    <div>
        <div class="pt-2 mx-8">
            <div class="collapse bg-base-100 mb-2" v-if="detailData.mode_success.length > 0">
                <input type="checkbox" v-model="isModeSuccess" />
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>文件hash重复</div>
                        <div class="badge badge-success gap-2 ml-2">
                            {{ detailData.mode_success_count }}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="flex justify-end gap-2">
                        <button class="btn btn-primary btn-active btn-sm" @click="handleRuleDelete('hash')">
                            规则删除
                        </button>
                        <button class="btn btn-neutral btn-active btn-sm" @click="handleRemoveFile('', 'hash')">
                            全部删除
                        </button>
                    </div>
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>文件名</th>
                                    <th>文件1</th>
                                    <th>文件2</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- row 1 -->
                                <tr v-for="(item, index) in detailData.mode_success" :key="'mode' + index">
                                    <th>{{ index + 1 }}</th>
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.path }}
                                        <button class="btn btn-sm btn-error btn-outline"
                                            @click="handleRemoveFile(item.path, 'single')">删除</button>
                                    </td>
                                    <td>{{ item.reason }}
                                        <button class="btn btn-sm btn-error btn-outline"
                                            @click="handleRemoveFile(item.reason, 'single')">删除</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.success.length > 0">
                <input type="checkbox" v-model="isSuccess" />
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>文件名称重复</div>
                        <div class="badge badge-success gap-2 ml-2">
                            {{ detailData.success_count }}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="flex justify-end gap-2">
                        <button class="btn btn-primary btn-active btn-sm" @click="handleRuleDelete('name')">
                            规则删除
                        </button>
                        <button class="btn btn-neutral btn-active btn-sm" @click="handleRemoveFile('', 'name')">
                            全部删除
                        </button>
                    </div>
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>文件名</th>
                                    <th>文件1</th>
                                    <th>文件2</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- row 1 -->
                                <tr v-for="(item, index) in detailData.success" :key="'mode' + index">
                                    <th>{{ index + 1 }}</th>
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.path }}
                                        <button class="btn btn-sm btn-error btn-outline hover:!text-white"
                                            @click="handleRemoveFile(item.path, 'single')">删除</button>
                                    </td>
                                    <td>{{ item.reason }}
                                        <button class="btn btn-sm btn-error btn-outline hover:!text-white"
                                            @click="handleRemoveFile(item.reason, 'single')">删除</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- 规则删除弹窗 -->
        <dialog id="rule_delete_modal" class="modal">
            <div class="modal-box">
                <h3 class="font-bold text-lg">规则删除</h3>
                <div class="py-4">
                    <div class="form-control">
                        <label class="label">
                            <span class="label-text">格式优先级</span>
                        </label>
                        <multi-select :options="formatList" v-model="ruleForm.formatPriority"></multi-select>
                    </div>
                    <div class="form-control">
                        <label class="label">
                            <span class="label-text">文件大小规则</span>
                        </label>
                        <single-select v-model="ruleForm.sizeRule" :options="sizeRuleList" :placeholder="'请选择'">
                        </single-select>
                    </div>
                    <div class="form-control">
                        <label class="label">
                            <span class="label-text">比特率规则</span>
                        </label>
                        <single-select v-model="ruleForm.bitrateRule" :options="bitRuleList" :placeholder="'请选择'">
                        </single-select>
                    </div>
                    <div class="form-control">
                        <label class="label">
                            <span class="label-text">采样率/位深规则</span>
                        </label>
                        <single-select v-model="ruleForm.sampleRule" :options="sampleRuleList" :placeholder="'请选择'">
                        </single-select>
                    </div>
                    <div class="form-control">
                        <label class="label">
                            <span class="label-text">文件名规则</span>
                        </label>
                        <single-select v-model="ruleForm.nameRule" :options="nameRuleList" :placeholder="'请选择'">
                        </single-select>
                    </div>
                </div>
                <div class="modal-action">
                    <form method="dialog">
                        <button class="btn btn-sm mr-2">取消</button>
                        <button class="btn btn-primary btn-sm" @click="confirmRuleDelete">确定</button>
                    </form>
                </div>
            </div>
        </dialog>
    </div>
</template>

<script>
import SingleSelect from "@/components/iview/SingleSelect";
import SingleInput from "@/components/iview/SingleInput";
import MultiSelect from "@/components/iview/MultiSelect";
export default {
    name: 'log-detail',
    props: {
        detailData: { type: Object, required: true }
    },
    components: { SingleInput, SingleSelect, MultiSelect },
    data() {
        return {
            id: this.$route.params.id,
            isOpen: true,
            timer: null,
            ruleForm: {
                sizeRule: 'smaller',
                formatPriority: ['flac', 'mp3'],
                nameRule: 'larger',
                bitrateRule: 'smaller',
                sampleRule: 'smaller',
                metadataRule: 'none',
                deleteMode: 'direct',
                targetDir: '',
                mode: ''
            },
            sizeRuleList: [
                { 'id': 'larger', 'name': '删除较大文件' },
                { 'id': 'smaller', 'name': '删除较小文件' },
                { 'id': 'none', 'name': '不判断' },
            ],
            bitRuleList: [
                { 'id': 'larger', 'name': '删除较大比特率' },
                { 'id': 'smaller', 'name': '删除较小比特率' },
                { 'id': 'none', 'name': '不判断' },
            ],
            sampleRuleList: [
                { 'id': 'larger', 'name': '删除较大采样率' },
                { 'id': 'smaller', 'name': '删除较小采样率' },
                { 'id': 'none', 'name': '不判断' },
            ],
            nameRuleList: [
                { 'id': 'larger', 'name': '删除含有后缀名' },
                { 'id': 'smaller', 'name': '删除不含有后缀名' },
                { 'id': 'none', 'name': '不判断' },
            ],
            formatList: [
                { id: 'mp3', name: 'MP3' },
                { id: 'wav', name: 'WAV' },
                { id: 'flac', name: 'FLAC' },
                { id: 'wma', name: 'WMA' },
                { id: 'ogg', name: 'OGG' }
            ],
        }
    },
    computed: {
        isSuccess() {
            return this.detailData.success.length > 0
        },
        isFail() {
            return this.detailData.fail.length > 0
        },
        isModeSuccess() {
            return this.detailData.mode_success.length > 0
        },
        isMissAA() {
            return this.detailData.miss_artist_album.length > 0
        },
        isMissArtist() {
            return this.detailData.miss_artist.length > 0
        },
        isMissAlbum() {
            return this.detailData.miss_album.length > 0
        },
        isMissTag() {
            return this.detailData.miss_tag.length > 0
        },
        isRunning() {
            return this.detailData.running.length > 0
        },
        isSkip() {
            return this.detailData.skip.length > 0
        }
    },
    mounted() {
        // this.handlePolling()
    },
    beforeDestroy() {
        this.setTimerNull() // 组件销毁前清空定时器
    },
    methods: {
        handleRuleDelete(mode) {
            this.ruleForm.mode = mode
            document.getElementById('rule_delete_modal').showModal()
        },
        confirmRuleDelete() {
            // 这里处理规则删除的逻辑
            try {
                this.isLoading = true
                this.$api.Task.deleteFiles({
                    log_id: this.id,
                    mode: this.ruleForm.mode,
                    rules: this.ruleForm,
                    paths: []
                }).then((res) => {
                    this.isLoading = false
                    if (res.result) {
                        this.$myMsg.notify({
                            title: '删除成功！',
                            type: 'success',
                            time: 2000,
                        })
                        this.$parent.fetchDetails()
                    } else {
                        this.$myMsg.notify({
                            title: '删除失败！',
                            content: res.message,
                            type: 'error',
                            time: 2000,
                        })
                    }
                })
                return true
            } catch (e) {
                console.warn(e)
                return false
            }
        },
        handleRemoveFile(path, mode) {
            try {
                this.isLoading = true
                this.$api.Task.deleteFiles(
                    {
                        'log_id': this.id,
                        'paths': [path],
                        'mode': mode
                    }).then((res) => {
                        this.isLoading = false
                        if (res.result) {
                            this.$myMsg.notify({
                                title: '删除成功！',
                                type: 'success',
                                time: 2000,
                            })
                            this.$parent.fetchDetails()
                        } else {
                            this.$myMsg.notify({
                                title: '删除失败！',
                                content: res.message,
                                type: 'error',
                                time: 2000,
                            })
                        }
                    })
                return true
            } catch (e) {
                console.warn(e)
                return false
            }
        }
    }
}
</script>

<style scoped></style>
