export default {
    namespaced: true,
    state: {
        tabSelect: ''
    },
    mutations: {
        SET_TAB_SELECT: (state, value) => {
            state.tabSelect = value
        }
    },
    actions: {
        setTabSelect({ commit }, value) {
            commit('SET_TAB_SELECT', value)
        }
    }
} 