// import {reUrl} from '../../axiosconfig/axiosconfig'
function getCookie(name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
}

// todo test
export const auth = {
    // server: 'http://127.0.0.1:88',
    server: '',
    get username() {
        return getCookie('username')
    },
    get salt() {
        return getCookie('salt')
    },
    get hash() {
        return getCookie('hash')
    }
}
