<template>
    <div class="p-4">
        <div class="mb-3">
            <svg class="h-4 w-4 fill-current cursor-pointer" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg"
                 @click="backDir">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M7.46961 13.5303L8.53027 12.4696L3.0606 6.99994L8.53027 1.53027L7.46961 0.469614L0.939283 6.99994L7.46961 13.5303Z"/>
            </svg>
        </div>
        <div class="flex items-center">
            <input type="text" placeholder="请输入文件夹路径："
                   v-model="filePath"
                   @keyup.enter="handleSearchFile"
                   class="input input-bordered input-sm w-full max-w-xs"/>
            <div @click="handleSearchFile(true)" class="cursor-pointer ml-2">
                <svg class="h-4 w-4 fill-current" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M8.75006 12.6893L13.4697 7.96967L14.5304 9.03033L8.00006 15.5607L1.46973 9.03033L2.53039 7.96967L7.25006 12.6893L7.25006 0H8.75006L8.75006 12.6893Z"/>
                </svg>
            </div>
        </div>
        <div class="flex items-center mt-2">
            <single-select v-model="searchWord"
                          :options="filterOptions"
                          :placeholder="'请输入搜索关键词, 按Enter确认'"
                          @change="handleSearch"
                          @enter="handleSearch"
                          @null="handleSearch">
            </single-select>
            <div class="dropdown dropdown-end">
                <label tabindex="0">
                    <div class="rounded-full cursor-pointer ml-2">
                        <svg class="h-4 w-4 fill-current" viewBox="0 0 16 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M0.969727 3.96967L4.93939 8.70228e-06H5.75006V12H4.25006V2.81066L2.03039 5.03033L0.969727 3.96967ZM11.7501 0H10.2501L10.2501 12H11.0607L15.0304 8.03033L13.9697 6.96967L11.7501 9.18934L11.7501 0Z"/>
                        </svg>
                    </div>
                </label>
                <ul tabindex="0"
                    class="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52">
                    <li><a href="javascript:;" @click="changeSorted('name')"
                           :class="{ 'isSelected': sortedField.includes('name') }">名称</a></li>
                    <li><a href="javascript:;" @click="changeSorted('update_time')"
                           :class="{ 'isSelected': sortedField.includes('update_time') }">修改时间</a></li>
                    <li><a href="javascript:;" @click="changeSorted('size')"
                           :class="{ 'isSelected': sortedField.includes('size') }">大小</a></li>
                    <li><a href="javascript:;" @click="changeSorted('state')"
                           :class="{ 'isSelected': sortedField.includes('state') }">状态</a></li>
                    <li><a href="javascript:;" @click="changeSorted('folder')"
                           :class="{ 'isSelected': sortedField.includes('folder') }">目录</a></li>
                </ul>
            </div>
        </div>
        <transition name="bk-slide-fade-down">
            <div style="margin-top: 10px; height: calc(100vh - 250px);" v-show="fadeShowDir">
                <RecycleScroller
                    class="scroller"
                    :items="flattenedTreeList"
                    :item-size="28"
                    :buffer="100"
                    key-field="id"
                    :min-item-size="28"
                    :prerender="20"
                    :dynamic="false"
                    v-slot="{ item }">
                    <div class="tree-node " :style="{ paddingLeft: item.level * 12 + 'px' }" :key="item.id">
                        <div class="flex items-center justify-start">
                            <!-- 复选框 -->
                            <input type="checkbox"
                                   :checked="item.checked"
                                   @change.stop="handleCheck(item)"
                                   class="checkbox checkbox-sm mr-2"/>
                            
                            <!-- 文件/文件夹图标 -->
                            <span class="mr-2">
                                <svg-icon :name="item.icon" :width="18" :height="18" :class="'fill-neutral-500'" />
                            </span>
                            
                            <!-- 节点标题 -->
                            <span :class="getNodeClass(item)"
                                  @click.stop="nodeClick(item)"
                                  :title="item.title"
                                  class="flex-1 truncate pr-4">
                                {{ item.title }}
                            </span>
                            
                            <!-- 文件夹数量标记 -->
                            <span v-if="!item.parent && item.children" 
                                  class="btn btn-xs ml-1"
                                  @click.stop="markFolder(item.name)"
                                  :title="'将文件夹标记为已完成'">
                                {{ item.children.length }}
                            </span>
                        </div>
                    </div>
                </RecycleScroller>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SingleSelect from "@/components/iview/SingleSelect"
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import SvgIcon from '@/components/SvgIcon.vue'

export default {
    name: 'FileTree',
    components: {
        SingleSelect,
        RecycleScroller,
        SvgIcon
    },
    data() {
        return {
            treeListOne: [],
            fadeShowDir: false,
            sortedField: localStorage.getItem('sortedField') ? JSON.parse(localStorage.getItem('sortedField')) : [],
            filterOptions: [
                {'id': 'state:null', 'name': '过滤: 未刮削'},
                {'id': 'state:success', 'name': '过滤: 成功'},
                {'id': 'state:failed', 'name': '过滤: 失败'}
            ],
            expandedNodes: new Set(),
            checkedNodesMap: new Map()
        }
    },
    computed: {
        ...mapGetters(['geFullPath']),
        filePath: {
            get() {
                return this.$store.state.common.fullPath
            },
            set(value) {
                this.$store.commit('setFullPath', value)
                return value
            }
        },
        checkedIds: {
            get() {
                return this.$store.state.common.checkedIds
            },
            set(value) {
                this.$store.commit('setCheckedIds', value)
                return value
            }
        },
        checkedData: {
            get() {
                return this.$store.state.common.checkedData
            },
            set(value) {
                this.$store.commit('setCheckedData', value)
                return value
            }
        },
        searchWord: {
            get() {
                return this.$store.state.common.searchWord
            },
            set(value) {
                this.$store.commit('setSearchWord', value)
                return value
            }
        },
        flattenedTreeList() {
            let flattened = []
            if (!this.treeListOne || !this.treeListOne.length) return flattened
            
            const processNode = (node, level = 0, parent = null) => {
                if (!node.id) {
                    node.id = `${node.name}-${level}-${Date.now()}`
                }
                
                const nodeData = {
                    ...node,
                    level,
                    parent,
                    expanded: this.expandedNodes.has(node.id),
                    checked: this.checkedIds.includes(node.id)
                }
                
                flattened.push(nodeData)
                
                if (node.children && node.children.length && nodeData.expanded) {
                    node.children.forEach(child => {
                        processNode(child, level + 1, node)
                    })
                }
            }
            
            processNode(this.treeListOne[0], 0, null)

            // 添加搜索过滤逻辑
            if (this.searchWord) {
                if (!this.searchWord.startsWith('state:')) {
                    // 按名称过滤
                    const searchLower = this.searchWord.toLowerCase()
                    return flattened.filter(node => 
                        node.name.toLowerCase().includes(searchLower)
                    )
                }
            }

            return flattened
        }
    },
    watch: {
        filePath: {
            handler() {
                this.handleSearchFile()
            }
        }
    },
    created() {
        const path = this.$route.query.path
        if (path) {
            this.filePath = path
            const target = this.$route.query.target || ''
            if (target === 'detail') {
                this.$router.replace({path: '/detail', query: {}})
            } else {
                this.$router.replace({path: '/', query: {}})
            }
        }
        this.handleSearchFile()
    },
    methods: {
        // 展开/折叠节点
        toggleNode(node) {
            if (!node.children || !node.children.length) return
            
            if (this.expandedNodes.has(node.id)) {
                this.expandedNodes.delete(node.id)
            } else {
                this.expandedNodes.add(node.id)
            }
            
            // 强制更新视图
            this.$nextTick(() => {
                this.treeListOne = [...this.treeListOne]
            })
        },
        
        // 获取节点样式类
        getNodeClass(node) {
            let classes = ['node-title']
            if (node.selected) classes.push('node-selected')
            
            if (node.state === 'success') {
                classes.push('text-slate-500')
            } else if (node.state === 'failed') {
                classes.push('text-red-500')
            } else {
                classes.push('text-current')
            }
            
            classes.push('hover:underline')
            return classes.join(' ')
        },
        
        // 处理节点勾选
        handleCheck(node) {
            const checked = !node.checked
            // 更新节点状态
            this.updateNodeCheckState(node, checked)
            // 同步到 store
            this.$store.commit('setCheckedIds', [...this.checkedIds])
            this.$store.commit('setCheckedData', [...this.checkedData])
        },
        
        // 更新节点的勾选状态
        updateNodeCheckState(node, checked) {
            if (!node) return
            
            // 只更新当前节点的勾选状态
            this.$set(node, 'checked', checked)
            
            // 如果是文件夹，更新其直接子节点
            if (node.children && node.children.length > 0) {
                node.children.forEach(child => {
                    this.$set(child, 'checked', checked)
                    // 更新选中节点的ID列表
                    if (checked) {
                        if (!this.checkedIds.includes(child.id)) {
                            this.checkedIds.push(child.id)
                            this.checkedData.push(child)
                        }
                    } else {
                        const index = this.checkedIds.indexOf(child.id)
                        if (index > -1) {
                            this.checkedIds.splice(index, 1)
                            this.checkedData.splice(index, 1)
                        }
                    }
                })
            }
            // 更新当前节点的ID
            if (checked) {
                if (!this.checkedIds.includes(node.id)) {
                    this.checkedIds.push(node.id)
                    this.checkedData.push(node)
                }
            } else {
                const index = this.checkedIds.indexOf(node.id)
                if (index > -1) {
                    this.checkedIds.splice(index, 1)
                    this.checkedData.splice(index, 1)
                }
            }
        },
        
        // 处理节点点击
        nodeClick(node) {
            if (node.icon === 'icon-folder') {
                if (node.children && node.children.length > 0) {
                    this.toggleNode(node)
                } else {
                    // 处理空文件夹的点击
                    if (this.filePath.endsWith('/')) {
                        this.filePath = this.filePath + node.name
                    } else {
                        this.filePath = this.filePath + '/' + node.name
                    }
                }
            } else {
                // 处理文件点击
                this.isShowEdit = true
                this.showId3List = this.checkedIds.length > 0
                this.musicInfo = this.baseMusicInfo
                this.fileName = node.name
                if (this.filePath.endsWith('/')) {
                    this.fullPath = this.filePath + node.name
                } else {
                    this.fullPath = this.filePath + '/' + node.name
                }
                this.$parent.showEditPageDrawer(this.filePath, node.name)
            }
        },
        
        // 返回上一级
        backDir() {
            this.filePath = this.backPath(this.filePath)
        },
        
        backPath(path) {
            if (path === '/') {
                return '/app'
            } else if (path === '/app') {
                return '/app'
            } else if (path === '/app/') {
                return '/app'
            }
            const regex = /\/([^\/]+)\/?$/
            const match = regex.exec(path)
            
            if (match) {
                const parentPath = path.slice(0, match.index)
                return parentPath
            }
            return path
        },
        
        // 文件目录
        handleSearchFile(refresh = false) {
            this.fadeShowDir = false
            this.checkedData = []
            this.checkedIds = []
            this.checkedNodesMap.clear() // 清除勾选状态缓存
            this.$parent.deleteFileID3List()
            this.$api.Task.fileList(
                {
                    'file_path': this.filePath,
                    sorted_fields: this.sortedField,
                    'search_word': this.searchWord,
                    'refresh': refresh
                }
            ).then((res) => {
                if (res.result) {
                    const scrollElement = document.querySelector('.scroller')
                    if (scrollElement) {
                        scrollElement.scrollTop = 0
                    }
                    this.treeListOne = res.data.file_list_data
                    this.expandAllNodes(this.treeListOne)
                    this.fadeShowDir = true
                    if (res.data.file_name !== '') {
                        let value = this.filePath.replace(res.data.file_name, '')
                        if (value.endsWith('/')) {
                            value = value.substring(0, value.length - 1)
                        }
                        this.$nextTick(() => {
                            this.filePath = value
                        })
                        this.$nextTick(() => {
                            this.nodeClick({'name': res.data.file_name, 'icon': 'icon-file'})
                        })
                    }
                } else {
                    if (res.message.status === 401) {
                        return
                    }
                    this.$myMsg.notify({
                        title: '查询失败！',
                        content: res.message || res.message.data.message,
                        type: 'error',
                        time: 5000,
                    })
                }
            })
            this.$parent.fetchFileID3FolderList('all')
        },
        
        // 过滤搜索
        handleSearch() {
            if (this.searchWord === '') {
                this.handleSearchFile()
                return
            }
            if (this.searchWord.startsWith('state:')) {
                this.handleSearchFile()
            }
        },
        
        // 更改排序条件
        changeSorted(element) {
            if (this.sortedField.includes(element)) {
                this.sortedField.splice(this.sortedField.indexOf(element), 1)
            } else {
                this.sortedField.push(element)
            }
            this.$store.commit('setSortedField', this.sortedField)
            this.handleSearchFile()
        },
        
        // 标记文件夹为已处理
        markFolder() {
            this.$api.Task.markFolderState({'full_path': this.filePath}).then((res) => {
                if (res.result) {
                    this.$myMsg.notify({
                        title: '标记成功！',
                        content: '',
                        type: 'success',
                        time: 2000,
                    })
                    this.handleSearchFile()
                } else {
                    this.$myMsg.notify({
                        title: '创建失败！',
                        content: res.messagee ? res.message : '创建失败',
                        type: 'error',
                        time: 5000,
                    })
                }
            })
        },
        
        // 展开所有节点 - 由于只有两层，这个方法可以简化
        expandAllNodes(nodes) {
            if (!nodes || !nodes.length) return
            
            const rootNode = nodes[0]
            if (!rootNode.id) {
                rootNode.id = `${rootNode.name}-${Date.now()}`
            }
            this.expandedNodes.add(rootNode.id)
            
            // 强制更新视图
            this.$nextTick(() => {
                this.treeListOne = JSON.parse(JSON.stringify(this.treeListOne))
            })
        }
    }
}
</script>

<style>
.scroller {
    height: 100%;
    overflow-y: auto;
    background-color: transparent;
    scroll-behavior: smooth;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
}

.tree-node {
    cursor: pointer;
    transition: background-color 0.2s;
    user-select: none;
    height: 24px;
    line-height: 20px;
    box-sizing: border-box;
    font-size: 12px;
}

.tree-node:hover {
    background-color: rgba(0, 0, 0, 0.03);
}

.node-title {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

.node-selected {
    color: #2d8cf0;
}

.isSelected {
    color: #2d8cf0;
    font-weight: bold;
}

.checkbox {
    cursor: pointer;
    transform: scale(0.8);
    margin-top: 1px;
}

/* 添加过渡动画 */
.bk-slide-fade-down-enter-active,
.bk-slide-fade-down-leave-active {
    transition: all 0.3s ease;
}

.bk-slide-fade-down-enter-from,
.bk-slide-fade-down-leave-to {
    transform: translateY(-10px);
    opacity: 0;
}
</style>
