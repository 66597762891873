<template>
    <ContentLoader v-slot :loading="playlist == null" class="h-screen-header">
        <Hero :image="playlist.image">
            <div class="flex flex-col justify-between h-56">
                <div class="ml-4 flex flex-col justify-start items-start z-10">
                    <div class="flex items-center ml-1">
                        <div class="text-4xl font-medium text-clip overflow-hidden h-10">{{ playlist.name }}</div>
                    </div>
                    <div class="flex flex-col my-2 text-gray-600">
                        <span>播放列表 {{ playlist.tracks.length }} 首歌曲</span>
                        <span>由{{ playlist.owner }} 创建于{{ formatDate(playlist.createdAt) }}</span>
                    </div>
                    <div v-if="playlist.comment" class="mb-3 text-gray-600 text-ellipsis overflow-hidden h-12">
                        {{ playlist.comment }}
                    </div>
                    <div class="badge badge-neutral" v-if="playlist.isDynamic">
                        动态歌单
                    </div>
                    <div class="badge badge-neutral" v-if="playlist.privacyLevel === 'exclude'">
                        过滤歌单
                    </div>
                </div>
                <div class="ml-4 z-10 flex">
                    <button class="btn btn-neutral btn-active btn-sm" @click="playNow(playlist.id)">
                        Play
                    </button>
                    <div class="drawer drawer-end w-16 ml-2">
                        <input id="my-drawer-4" type="checkbox" class="drawer-toggle" />
                        <div class="drawer-content">
                            <!-- Page content here -->
                            <label for="my-drawer-4" class="btn btn-neutral btn-active btn-sm"
                                @click="handleEdit">EDIT</label>
                        </div>
                        <div class="drawer-side">
                            <label for="my-drawer-4" aria-label="close sidebar" class="drawer-overlay"></label>
                            <div class="p-4 w-80 min-h-full bg-base-200 text-base-content">
                                <div class="font-medium text-xl mb-4">编辑播放列表信息</div>

                                <div class="mb-1">名称:</div>
                                <div>
                                    <input type="text" v-model="FormData.name"
                                        class="input input-bordered input-sm w-full max-w-xs" />
                                </div>
                                <div class="mb-1 mt-1">封面:</div>
                                <div>
                                    <bk-upload :files="files1" :theme="'picture'" :multiple="false"
                                        :with-credentials="true" :header="uploadHeader" :handle-res-code="handleRes"
                                        :size="{ maxFileSize: 5, maxImgSize: 20 }" :url="uploadUrl"
                                        name="upload_file"></bk-upload>
                                </div>
                                <div class="mb-1 mt-1">描述:</div>
                                <textarea class="textarea textarea-bordered textarea-xs w-full max-w-xs h-48"
                                    placeholder="." v-model="FormData.description"></textarea>
                                <button class="btn btn-neutral btn-sm btn-wide" @click="handleSave">保存</button>
                                <div class="mt-4">封面推荐:</div>
                                <div v-for="(item2, index) in uniqueTrackList" :key="index">
                                    <div class="flex justify-center items-center mt-1 mb-2">
                                        <bk-image fit="contain" :src="item2.image"
                                            class="mask mask-squircle w-16 h-16 cursor-pointer"
                                            @click="handleCopy('playlist_img', item2.image)">
                                        </bk-image>
                                        <div class="flex-1 ml-2">
                                            <div class="cursor-pointer hover:underline"
                                                @click="handleCopy('name', item2.artist)">{{ item2.artist }}</div>
                                            <div class="truncate w-56 text-gray-400 cursor-pointer hover:underline"
                                                @click="handleCopy('description', item2.album)">{{ item2.album }}</div>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-neutral btn-active btn-sm" onclick="playlist_delete.showModal()">
                        DELETE
                    </button>
                    <div class="dropdown ml-2" ref="selectContainer">
                        <div class="dropdown dropdown-end">
                            <div tabindex="0" role="button" class="btn btn-sm btn-active" @click="toggleDropdown">
                                <!-- 下拉按钮图标 -->
                                <svg t="1735448632254" class="icon h-6 w-6 fill-current" viewBox="0 0 1024 1024"
                                    version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5701" width="200"
                                    height="200">
                                    <path
                                        d="M227.14123 413.647995c-52.14973 0-94.587262 42.439578-94.587262 94.587262 0 52.14973 42.437531 94.587262 94.587262 94.587262 52.147684 0 94.587262-42.437531 94.587262-94.587262C321.728492 456.087573 279.288914 413.647995 227.14123 413.647995z">
                                    </path>
                                    <path
                                        d="M510.903016 413.647995c-52.14973 0-94.587262 42.439578-94.587262 94.587262 0 52.14973 42.437531 94.587262 94.587262 94.587262 52.147684 0 94.587262-42.437531 94.587262-94.587262C605.490278 456.087573 563.051723 413.647995 510.903016 413.647995z">
                                    </path>
                                    <path
                                        d="M794.665825 413.647995c-52.14973 0-94.587262 42.439578-94.587262 94.587262 0 52.14973 42.437531 94.587262 94.587262 94.587262 52.147684 0 94.587262-42.437531 94.587262-94.587262C889.253086 456.087573 846.813508 413.647995 794.665825 413.647995z"
                                        p-id="5704"></path>
                                </svg>
                            </div>
                            <ul v-if="isDropdownOpen" tabindex="0" :class="['dropdown-content menu bg-base-100 rounded-box z-10 w-52 p-2 shadow !leading-3',
                                dropdownDirection === 'up' ? 'bottom-full' : 'top-full']" @click.stop=""
                                @blur="closeDropdown">
                                <li v-if="isMiPlay">
                                    <a @click.stop="playInMi(playlist.id)">
                                        <svg t="1735703541751" class="h-4 w-4 fill-current" viewBox="0 0 1077 1024"
                                            version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2440" width="200"
                                            height="200">
                                            <path
                                                d="M363.789474 116.304842h646.736842a40.421053 40.421053 0 1 1 0 80.842105h-646.736842a40.421053 40.421053 0 1 1 0-80.842105z m-323.368421 397.473684h970.105263a40.421053 40.421053 0 1 1 0 80.842106h-970.105263a40.421053 40.421053 0 1 1 0-80.842106z m0 397.473685h970.105263a40.421053 40.421053 0 1 1 0 80.842105h-970.105263a40.421053 40.421053 0 1 1 0-80.842105zM0 26.947368A26.947368 26.947368 0 0 1 44.085895 6.197895l163.678316 135.114105a26.947368 26.947368 0 0 1 0 41.552842L44.085895 318.032842A26.947368 26.947368 0 0 1 0 297.283368V26.947368z">
                                            </path>
                                        </svg>
                                        小爱音箱播放
                                    </a>
                                </li>
                                <li>
                                    <a @click.stop="exportPlaylist(playlist.id)">
                                        <svg t="1735703541751" class="h-4 w-4 fill-current" viewBox="0 0 1077 1024"
                                            version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2440" width="200"
                                            height="200">
                                            <path
                                                d="M363.789474 116.304842h646.736842a40.421053 40.421053 0 1 1 0 80.842105h-646.736842a40.421053 40.421053 0 1 1 0-80.842105z m-323.368421 397.473684h970.105263a40.421053 40.421053 0 1 1 0 80.842106h-970.105263a40.421053 40.421053 0 1 1 0-80.842106z m0 397.473685h970.105263a40.421053 40.421053 0 1 1 0 80.842105h-970.105263a40.421053 40.421053 0 1 1 0-80.842105zM0 26.947368A26.947368 26.947368 0 0 1 44.085895 6.197895l163.678316 135.114105a26.947368 26.947368 0 0 1 0 41.552842L44.085895 318.032842A26.947368 26.947368 0 0 1 0 297.283368V26.947368z">
                                            </path>
                                        </svg>
                                        导出播放列表
                                    </a>
                                </li>
                                <li>
                                    <a @click.stop="updatePlaylistProps(playlist.id, 'exclude')">
                                        <svg t="1735703541751" class="h-4 w-4 fill-current" viewBox="0 0 1077 1024"
                                            version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2440" width="200"
                                            height="200">
                                            <path
                                                d="M363.789474 116.304842h646.736842a40.421053 40.421053 0 1 1 0 80.842105h-646.736842a40.421053 40.421053 0 1 1 0-80.842105z m-323.368421 397.473684h970.105263a40.421053 40.421053 0 1 1 0 80.842106h-970.105263a40.421053 40.421053 0 1 1 0-80.842106z m0 397.473685h970.105263a40.421053 40.421053 0 1 1 0 80.842105h-970.105263a40.421053 40.421053 0 1 1 0-80.842105zM0 26.947368A26.947368 26.947368 0 0 1 44.085895 6.197895l163.678316 135.114105a26.947368 26.947368 0 0 1 0 41.552842L44.085895 318.032842A26.947368 26.947368 0 0 1 0 297.283368V26.947368z">
                                            </path>
                                        </svg>
                                        标记为过滤列表
                                    </a>
                                </li>
                                <li>
                                    <a @click.stop="duplicatePlaylist(playlist.id)">
                                        <svg t="1735703541751" class="h-4 w-4 fill-current" viewBox="0 0 1077 1024"
                                            version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2440" width="200"
                                            height="200">
                                            <path
                                                d="M363.789474 116.304842h646.736842a40.421053 40.421053 0 1 1 0 80.842105h-646.736842a40.421053 40.421053 0 1 1 0-80.842105z m-323.368421 397.473684h970.105263a40.421053 40.421053 0 1 1 0 80.842106h-970.105263a40.421053 40.421053 0 1 1 0-80.842106z m0 397.473685h970.105263a40.421053 40.421053 0 1 1 0 80.842105h-970.105263a40.421053 40.421053 0 1 1 0-80.842105zM0 26.947368A26.947368 26.947368 0 0 1 44.085895 6.197895l163.678316 135.114105a26.947368 26.947368 0 0 1 0 41.552842L44.085895 318.032842A26.947368 26.947368 0 0 1 0 297.283368V26.947368z">
                                            </path>
                                        </svg>
                                        列表去重复
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Hero>
        <TrackList v-if="playlist.tracks.length > 0" :tracks="playlist.tracks" :index-field="'index'" :draggable="true"
            class="overflow-y-auto h-[58%]">
        </TrackList>
        <dialog id="playlist_delete" class="modal modal-bottom sm:modal-middle">
            <div class="modal-box">
                <h3 class="text-lg font-bold">删除播放列表</h3>
                <p class="py-4">从音乐收藏中删除此播放列表，但不会删除源文件。</p>
                <div class="modal-action">
                    <form method="dialog">
                        <!-- if there is a button in form, it will close the modal -->
                        <button class="btn btn-neutral btn-sm mr-2" @click="deletePlaylist">确认</button>
                        <button class="btn btn-sm">取消</button>
                    </form>
                </div>
            </div>
        </dialog>
    </ContentLoader>
</template>
<script>
import TrackList from '@/views/library/track/TrackList'
import Hero from '../../../components/library/Hero'
import ContentLoader from '@/components/library/ContentLoader'

const { getCoverArtUrl } = require('../../../api/apiUrl/subsonic/serializers')
export default {
    components: {
        TrackList, Hero, ContentLoader
    },
    props: {
    },
    data() {
        return {
            playlist: null,
            showEditModal: false,
            id: this.$route.params.id,
            uploadHeader: [
                { name: 'X-CSRFToken', value: this.getCookie('django_vue_cli_csrftoken') },
                { name: 'AUTHORIZATION', value: this.getCookie('AUTHORIZATION') }
            ],
            files1: [],
            uploadUrl: '/apimt/upload_image/',
            FormData: {},
            artistsData: [],
            uniqueTrackList: [],
            isMiPlay: localStorage.getItem('mi_play_in_pl') === '1' || false,
            isDropdownOpen: false,
            dropdownDirection: 'down',
        }
    },
    async created() {
        this.playlist = await this.$api.subsonic.getPlaylist(this.id)
        this.FormData = {
            name: this.playlist.name,
            description: this.playlist.comment,
            playlist_img: this.playlist.image ? this.playlist.image : ''
        }
        this.uniqueTrackList = this.playlist.tracks.reduce((unique, item) => {
            // 判断当前元素的 id 是否已经存在于 unique 列表中
            if (!unique.some(elem => elem.album === item.album)) {
                unique.push(item)
            }
            return unique
        }, [])
        this.files1 = [
            {
                name: 'cover.png',
                status: 'done',
                url: this.playlist.image
            }
        ]
    },
    methods: {
        async playNow(id) {
            const playlist = await this.$api.subsonic.getPlaylist(id)
            return this.$store.dispatch('playTrackList', {
                tracks: playlist.tracks
            })
        },
        playInMi() {
            this.$api.Task.miServicePlayList({ 'pl_id': this.id }).then((res) => {
                if (res.result) {
                    this.$myMsg.notify({
                        title: '播放成功！',
                        type: 'success',
                        time: 2000,
                    })
                } else {
                    this.$myMsg.notify({
                        title: '播放失败！',
                        type: 'error',
                        time: 2000,
                    })
                }
            })
        },
        removeTrack(index) {
            this.playlist.tracks.splice(index, 1)
            return this.playlistStore.removeTrack(this.id, index)
        },
        updatePlaylist(value) {
            this.playlist = value
            return this.playlistStore.update(this.playlist)
        },
        deletePlaylist() {
            this.$api.subsonic.deletePlaylist(this.id).then((res) => {
                this.$myMsg.notify({
                    title: '删除成功！',
                    type: 'success',
                    time: 2000,
                })
                this.$router.push({ path: '/playlists/recently-added/' })
            })
        },
        handleEdit() {
            this.showEditModal = true
            this.genrePlaylistCoverFuc()
        },
        handleRes(response) {
            if (response.result) {
                this.FormData.playlist_img = response.data
                return true
            } else {
                return false
            }
        },
        handleCopy(k, v) {
            if (k === 'playlist_img') {
                this.files1 = [
                    {
                        name: 'cover.png',
                        status: 'done',
                        url: v
                    }
                ]
                this.FormData.playlist_img = v
            } else {
                this.FormData[k] += ',' + v
            }
        },
        handleSave() {
            this.$api.Task.putPlayList({ id: this.id, ...this.FormData }).then((res) => {
                if (res.result) {
                    this.$myMsg.notify({
                        title: '修改成功！',
                        type: 'success',
                        time: 2000,
                    })
                    this.$api.subsonic.getPlaylist(this.id).then((res) => {
                        this.playlist = res
                    })
                }
            })
        },
        genrePlaylistCoverFuc() {
            this.$api.Task.genrePlaylistCover({ 'id': this.id }).then((res) => {
                if (res.result) {
                    const img = getCoverArtUrl({ 'coverArt': res.data.coverArt })
                    if (this.uniqueTrackList && this.uniqueTrackList[0].coverArt === this.id) {
                        this.uniqueTrackList[0] = {
                            'image': img,
                            'artist': res.data.artist,
                            'album': res.data.album,
                            'coverArt': this.id
                        }
                    } else {
                        this.uniqueTrackList.unshift({
                            'image': img,
                            'artist': res.data.artist,
                            'album': res.data.album,
                            'coverArt': this.id
                        })
                    }
                }
            })
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        closeDropdown() {
            this.isDropdownOpen = false;
        },
        exportPlaylist(id) {
            this.$api.subsonic.exportPlaylist(id).then((res) => {
                window.open(res.url, '_blank')
                console.log(res)
            })
        },
        updatePlaylistProps(id, privacy_level) {
            this.$api.subsonic.updatePlaylistProps(id, privacy_level).then((res) => {
                this.$myMsg.notify({
                    title: '更新成功！',
                    type: 'success',
                    time: 2000,
                })
            })
        },
        duplicatePlaylist(id) {
            this.$api.subsonic.duplicatePlaylist(id).then((res) => {
                this.$myMsg.notify({
                    title: '去重成功！',
                    type: 'success',
                    time: 2000,
                })
                this.$api.subsonic.getPlaylist(id).then((res) => {
                    this.playlist = res
                })
            })
        }
    }
}
</script>
