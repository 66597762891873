<template>
    <div>
        <div role="tablist" class="tabs tabs-boxed bg-base-100 mx-2 mt-2 !flex">
            <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect2 === 'log' }"
                @click="handleTab2('log')">操作日志</a>
            <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect2 === 'runtime' }"
                @click="handleTab2('runtime')">运行日志</a>
        </div>
        <div v-if="tabSelect2 === 'log'">
            <div role="tablist" class="tabs tabs-boxed bg-base-100 mx-2 mt-2 !flex">
                <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect === '' }"
                    @click="handleTabInfo('')">全部</a>
                <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect === '手动修改' }"
                    @click="handleTabInfo('手动修改')">手动修改</a>
                <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect === '自动刮削' }"
                    @click="handleTabInfo('自动刮削')">自动刮削</a>
                <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect === '后台刮削' }"
                    @click="handleTabInfo('后台刮削')">后台刮削</a>
                <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect === '导入收藏' }"
                    @click="handleTabInfo('导入收藏')">导入收藏</a>
                <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect === '重复检查' }"
                    @click="handleTabInfo('重复检查')">重复检查</a>
                <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelect === '删除空文件夹' }"
                    @click="handleTabInfo('删除空文件夹')">删除空文件夹</a>
            </div>
            <div class="overflow-x-auto bg-base-100 m-2 rounded-xl">
                <table class="table table-zebra">
                    <!-- head -->
                    <thead>
                        <tr>
                            <th></th>
                            <th>用户</th>
                            <th>日志等级</th>
                            <th>操作</th>
                            <th>详情</th>
                            <th>状态</th>
                            <th>操作时间</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in auditLogData" :key="index" class="hover:bg-gray-100">
                            <th>{{item.id}}</th>
                            <td>{{item.user}}</td>
                            <td>
                                <span v-if="item.level === 'info'" class="badge">
                                    {{item.level}}
                                </span>
                                <span v-else-if="item.level === 'error'" class="badge badge-secondary">
                                    {{item.level}}
                                </span>
                                <span v-else-if="item.level === 'warning'" class="badge badge-warning">
                                    {{item.level}}
                                </span>
                            </td>
                            <td>{{item.action}}</td>
                            <td class="max-w-md break-words">
                                {{item.extra}}
                                <a class="link link-accent" v-if="hasLogDetail.indexOf(item.action) !== -1" @click="handleRedirect(item)">查看详情</a>
                            </td>
                            <td>
                                <span v-if="item.status === 'running'" class="badge">
                                    进行中
                                </span>
                                <span v-else-if="item.status === 'success'" class="badge font-light">
                                    成功
                                </span>
                                <span v-else-if="item.status === 'stop'" class="badge font-light">
                                    停止
                                </span>
                                <span v-else class="badge">
                                    {{item.status}}
                                </span>
                            </td>
                            <td>{{item.created_at}}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="flex justify-center my-4">
                    <div class="join shadow-md rounded-lg">
                        <button class="join-item btn btn-sm hover:bg-primary hover:text-white transition-colors duration-200" 
                            :disabled="currentPage === 1" 
                            :class="{'opacity-50 cursor-not-allowed': currentPage === 1}"
                            @click="handlePageChange(currentPage - 1)">
                            <i class="fas fa-chevron-left mr-1"></i>上一页
                        </button>
                        <button class="join-item btn btn-sm bg-base-200">
                            第 <span class="font-bold mx-1">{{currentPage}}</span> 页
                        </button>
                        <button class="join-item btn btn-sm hover:bg-primary hover:text-white transition-colors duration-200"
                            :disabled="!hasNextPage"
                            :class="{'opacity-50 cursor-not-allowed': !hasNextPage}"
                            @click="handlePageChange(currentPage + 1)">
                            下一页<i class="fas fa-chevron-right ml-1"></i>
                        </button>
                        <input type="number" 
                            class="join-item input input-bordered input-sm w-20 focus:outline-none focus:border-primary" 
                            v-model="jumpPage" 
                            min="1" 
                            placeholder="页码" />
                        <button class="join-item btn btn-sm bg-primary text-white hover:bg-primary-focus transition-colors duration-200"
                            @click="handlePageChange(Number(jumpPage))">
                            跳转
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="flex justify-between items-center">
                <div role="tablist" class="tabs tabs-boxed bg-base-100 mx-2 mt-2 !flex flex-1">
                    <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelectRuntime === 'gunicorn' }"
                        @click="handleRuntimeTabInfo('gunicorn')">Gunicorn</a>
                    <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelectRuntime === 'nginx' }"
                        @click="handleRuntimeTabInfo('nginx')">Nginx</a>
                    <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelectRuntime === 'beat' }"
                        @click="handleRuntimeTabInfo('beat')">Beat</a>
                    <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelectRuntime === 'worker' }"
                        @click="handleRuntimeTabInfo('worker')">Worker</a>
                    <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelectRuntime === 'redis' }"
                        @click="handleRuntimeTabInfo('redis')">Redis</a>
                    <a role="tab" :class="{ 'tab': true, 'tab-active': tabSelectRuntime === 'xiaoai' }"
                        @click="handleRuntimeTabInfo('xiaoai')">Xiaoai</a>
                </div>
                <div class="flex items-center mr-2 justify-end mt-2">
                    <input type="number" class="input input-bordered input-sm w-20" v-model="pollInterval" min="1" max="60">
                    <span class="ml-2">秒轮询一次</span>
                    <button :class="['btn btn-sm ml-2', isPolling ? 'btn-primary' : 'btn-neutral']" @click="togglePolling">
                        {{ isPolling ? '停止' : '开始' }}
                    </button>
                    <button class="btn btn-sm ml-2" onclick="download_log.showModal()">反馈日志</button>
                </div>
            </div>
            <div class="flex flex-col md:flex-row">
                <div class="m-2 rounded-xl md:w-1/2">
                    <textarea ref="logTextarea" class="textarea textarea-bordered h-screen-log w-full" placeholder="运行日志" v-model="runtimeText">
                    </textarea>
                </div>
                <div class="m-2 rounded-xl md:w-1/2">
                    <textarea ref="failLogTextarea" class="textarea textarea-bordered h-screen-log w-full" placeholder="错误日志" v-model="runtimeTextFail">
                    </textarea>
                </div>
            </div>
        </div>
        <dialog id="download_log" ref="download_log" class="modal modal-bottom sm:modal-middle">
            <div class="modal-box md:overflow-y-visible">
                <h3 class="text-lg font-bold">反馈日志</h3>
                <div class="py-4 z-20">
                    <textarea class="textarea textarea-bordered h-40 w-full" placeholder="您应该提供尽可能详细的信息，这样开发者才能快速理解并定位问题，填写反馈理由,至少20字" v-model="reason">
                    </textarea>
                    <div class="text-sm text-gray-400 mt-1">同时会上传日志给开发者！</div>
                </div>
                <div class="modal-action">
                    <form method="dialog">
                        <!-- if there is a button in form, it will close the modal -->
                        <button class="btn btn-neutral btn-sm mr-2" @click="handleUploadLog">确认</button>
                        <button class="btn btn-sm">取消</button>
                    </form>
                </div>
            </div>
        </dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                size: 'small',
                auditLogData: [],
                tabSelectRuntime: 'gunicorn',
                tabSelect2: 'log',
                runtimeText: '',
                runtimeTextFail: '',
                hasLogDetail: ['导入收藏', '自动刮削', '后台刮削', '重复检查'],
                pollInterval: 5,
                isPolling: true,
                pollingTimer: null,
                currentPage: 1,
                hasNextPage: true,
                jumpPage: 1,
                reason: ''
            }
        },
        computed: {
            tabSelect: {
                get() {
                    return this.$store.state.log.tabSelect
                },
                set(value) {
                    this.$store.dispatch('log/setTabSelect', value)
                }
            }
        },
        created() {
            this.fetchAuditLog()
        },
        beforeDestroy() {
            this.stopPolling()
        },
        methods: {
            handlePageChange(page) {
                this.currentPage = page
                this.fetchAuditLog()
            },
            fetchAuditLog() {
                this.$api.Task.getAuditLog({
                    page: this.currentPage,
                    page_size: 50, 
                    action: this.tabSelect
                }).then(res => {
                    if (res.result) {
                        this.auditLogData = res.data.items
                        this.hasNextPage = res.data.count > this.currentPage * 50
                    }
                })
            },
            fetchRuntimeLog() {
                this.$api.Task.getRuntimeLog({type: this.tabSelectRuntime}).then(res => {
                    if (res.result) {
                        this.runtimeText = res.data.log
                        this.runtimeTextFail = res.data.fail_log
                        this.$nextTick(() => {
                            if(this.$refs.logTextarea) {
                                this.$refs.logTextarea.scrollTop = this.$refs.logTextarea.scrollHeight
                            }
                            if(this.$refs.failLogTextarea) {
                                this.$refs.failLogTextarea.scrollTop = this.$refs.failLogTextarea.scrollHeight
                            }
                        })
                    }
                })
            },
            handleTabInfo(v) {
                if (v === this.tabSelect) {
                    this.tabSelect = ''
                } else {
                    this.tabSelect = v
                }
                this.currentPage = 1
                this.fetchAuditLog()
            },
            handleTab2(v) {
                this.tabSelect2 = v
                if (v === 'log') {
                    this.stopPolling()
                    this.currentPage = 1
                    this.fetchAuditLog()
                } else {
                    if (this.isPolling) {
                        this.startPolling()
                    } else {
                        this.fetchRuntimeLog()
                    }
                }
            },
            handleRuntimeTabInfo(v) {
                if (v === this.tabSelectRuntime) {
                    this.tabSelectRuntime = ''
                } else {
                    this.tabSelectRuntime = v
                }
                this.fetchRuntimeLog()
            },
            handleRedirect(item) {
                this.$router.push({path: '/log_detail/' + item.id + '/'})
            },
            togglePolling() {
                if (this.isPolling) {
                    this.stopPolling()
                } else {
                    this.startPolling()
                }
            },
            startPolling() {
                this.isPolling = true
                this.pollingTimer = setInterval(() => {
                    this.fetchRuntimeLog()
                }, this.pollInterval * 1000)
            },
            stopPolling() {
                this.isPolling = false
                if (this.pollingTimer) {
                    clearInterval(this.pollingTimer)
                    this.pollingTimer = null
                }
            },
            handleUploadLog() {
                if (this.reason.length < 20) {
                    this.$myMsg.notify({
                        title: '反馈失败！',
                        content: '反馈理由至少20字',
                        type: 'error',
                        time: 3000,
                    })
                    return
                }
                this.$api.Task.uploadLog({
                    reason: this.reason
                }).then(res => {
                    if (res.result) {
                        this.$refs["download_log"].close()
                        this.$myMsg.notify({
                            title: '反馈成功！',
                            type: 'success',
                            time: 3000,
                        })
                    }
                })
            }
        }
    }
</script>
<style lang="postcss">
.bk-table-header .custom-header-cell {
    color: inherit;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-position: under;
}
.h-screen-log {
    height: calc(100vh - 140px);
}
</style>
