<template>
    <svg v-if="name === 'check'" :class="iconClass" :width="width" :height="height" :viewBox="viewBox" :xmlns="xmlns"
        :p-id="pId">
        <path
            d="M511.082 36.049c-263.069 0-476.33 212.903-476.33 475.534s213.261 475.534 476.33 475.534c263.073 0 476.33-212.903 476.33-475.534 0-262.63-213.255-475.534-476.33-475.534zM772.988 397.6l-306.63 313.276c-14.106 14.413-36.99 14.413-51.094 0l-166.087-169.696c-14.115-14.414-14.115-37.788 0-52.206 14.105-14.419 36.99-14.419 51.094 0l140.541 143.585 281.072-287.169c14.115-14.418 37.001-14.418 51.105 0 14.115 14.417 14.115 37.792 0 52.21z" />
    </svg>

    <svg v-else-if="name === 'document'" :class="iconClass" :width="width" :height="height" :viewBox="viewBox"
        :xmlns="xmlns" :p-id="pId">
        <path
            d="M873.48 933.77c-16.22 0-29.36-14.33-29.36-32v-754c0-11.05-8.22-20-18.35-20H230.75c-16.22 0-29.36-14.33-29.36-32s13.15-32 29.36-32h598.69c40.54 0 73.4 35.82 73.4 80v758c0.01 17.67-13.14 32-29.36 32z M713.71 224.22c8.82 0 16 7.18 16 16v640c0 8.82-7.18 16-16 16h-534c-8.82 0-16-7.18-16-16v-640c0-8.82 7.18-16 16-16h534m0-64h-534c-44.18 0-80 35.82-80 80v640c0 44.18 35.82 80 80 80h534c44.18 0 80-35.82 80-80v-640c0-44.18-35.82-80-80-80z M648.9 397.33H242.01c-17.67 0-32 14.33-32 32s14.33 32 32 32H648.9c17.67 0 32-14.33 32-32s-14.32-32-32-32z M564.02 549.69H242.01c-17.67 0-32 14.33-32 32s14.33 32 32 32h322.01c17.67 0 32-14.33 32-32s-14.32-32-32-32z" />
    </svg>

    <svg v-else-if="name === 'close'" :class="iconClass" :width="width" :height="height" :viewBox="viewBox"
        :xmlns="xmlns" :p-id="pId">
        <path
            d="M567.168 512l189.184 188.352a40.064 40.064 0 0 1 0.768 55.68 37.76 37.76 0 0 1-54.4 0.832L512 566.912l-190.72 189.952a37.76 37.76 0 0 1-54.4-0.768 40.064 40.064 0 0 1 0.768-55.68L456.832 512 267.648 323.648a40.064 40.064 0 0 1-0.768-55.68 37.76 37.76 0 0 1 54.4-0.832L512 457.088l190.72-189.952a37.76 37.76 0 0 1 54.4 0.768 40.064 40.064 0 0 1-0.768 55.68L567.168 512z" />
    </svg>

    <svg v-else-if="name === 'minus'" :class="iconClass" :width="width" :height="height" :viewBox="viewBox"
        :xmlns="xmlns" :p-id="pId">
        <path d="M765.610667 518.485333H298.666667V469.333333h466.944z" fill-opacity=".85" />
    </svg>

    <svg v-else-if="name === 'gain'" :class="iconClass" :width="width" :height="height" :viewBox="viewBox"
        :xmlns="xmlns" :p-id="pId">
        <path
            d="M896 128a128 128 0 0 1 128 128v512a128 128 0 0 1-128 128H128a128 128 0 0 1-128-128V256a128 128 0 0 1 128-128h768z m0 85.333333H128a42.666667 42.666667 0 0 0-42.368 37.674667L85.333333 256v512a42.666667 42.666667 0 0 0 37.674667 42.368L128 810.666667h768a42.666667 42.666667 0 0 0 42.368-37.674667L938.666667 768V256a42.666667 42.666667 0 0 0-37.674667-42.368L896 213.333333zM445.44 256l14.037333 37.290667c26.282667 67.84 57.045333 134.016 94.506667 167.509333a154.026667 154.026667 0 0 0 98.218667 40.533333v-170.666666c14.506667 59.733333 40.021333 103.125333 76.501333 130.133333s78.037333 40.533333 124.629333 40.533333v21.333334c-46.592 0-88.149333 13.525333-124.629333 40.533333s-61.994667 70.4-76.501333 130.133333v-170.666666a154.026667 154.026667 0 0 0-98.218667 40.533333c-37.461333 33.493333-68.224 99.626667-94.506667 167.509333L445.44 768l2.304-245.333333c-60.586667 13.098667-100.352 26.624-119.381333 40.533333-45.568 33.28-65.28 78.933333-73.045334 105.344l-2.432 8.96-1.450666 6.741333-1.536-161.578666H170.666667v-21.333334h79.232l1.536-161.578666 1.450666 6.784 2.432 8.96c7.808 26.410667 27.477333 72.021333 73.045334 105.301333 19.029333 13.909333 58.794667 27.434667 119.381333 40.533333L445.44 256z" />
    </svg>

    <svg v-else-if="name === 'duplicate-file'" :class="iconClass" :width="width" :height="height" :viewBox="viewBox"
        :xmlns="xmlns" :p-id="pId">
        <path
            d="M832 176V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64v704c0 35.3 28.7 64 64 64h112c8.8 0 16 7.2 16 16v112c0 35.3 28.7 64 64 64h704c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64H848c-8.8 0-16-7.2-16-16zM176 768H80c-8.8 0-16-7.2-16-16V80c0-8.8 7.2-16 16-16h672c8.8 0 16 7.2 16 16v96c0 8.8-7.2 16-16 16H256c-35.3 0-64 28.7-64 64v496c0 8.8-7.2 16-16 16z m768 192H272c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h672c8.8 0 16 7.2 16 16v672c0 8.8-7.2 16-16 16z"
            p-id="6175" />
    </svg>

    <svg v-else-if="name === 'fix-encoding'" :class="iconClass" :width="width" :height="height" :viewBox="viewBox"
        :xmlns="xmlns" :p-id="pId">
        <path
            d="M141.8 735.9H64.2v130.4c0 15.8 6.2 30.9 17.3 42 11.1 11.1 26.2 17.4 41.9 17.4h132.4v-77.5h-114V735.9zM883.2 287.9v-114h-115V98.3h132.3v-0.1c32.8 0 59.3 26.6 59.3 59.3v130.4h-76.6zM858.2 735.1L562.7 439.6c29.1-74.3 12.9-163.1-46.8-222.8-64.6-64.6-161.5-77.5-239-40.4l140.4 140.5-98.5 98.5-140.5-140.5c-37.1 77.5-24.2 174.4 40.4 239 59.7 59.7 148.6 75.9 222.8 46.8L737 856.2c12.9 12.9 33.9 12.9 45.2 0l75.9-75.9c13-11.2 13-32.2 0.1-45.2z" />
    </svg>

    <svg v-else-if="name === 'import-music'" :class="iconClass" :width="width" :height="height" :viewBox="viewBox"
        :xmlns="xmlns" :p-id="pId">
        <path
            d="M13.192915 512.006566A41.682289 41.682289 0 0 0 54.875204 554.170731h510.547806L389.899614 729.33272a41.682289 41.682289 0 1 0 59.029831 59.029831L670.712954 566.217635a76.738781 76.738781 0 0 0 0-108.422139L448.929445 235.65058a41.441351 41.441351 0 0 0-29.514916-12.046904 41.682289 41.682289 0 0 0-29.514915 71.317673L565.543479 469.8424H54.875204A41.682289 41.682289 0 0 0 13.192915 512.006566z" />
        <path
            d="M827.925055 940.514953H310.751452c-55.897636 0-99.146023-64.571407-99.146023-120.469043a41.802758 41.802758 0 1 0-83.485047 0 219.374128 219.374128 0 0 0 49.994653 137.455178A171.668387 171.668387 0 0 0 310.751452 1024h517.173603a183.112946 183.112946 0 0 0 182.872008-182.872008V180.716696a181.426379 181.426379 0 0 0-180.703565-180.703565H310.751452A180.703565 180.703565 0 0 0 127.879444 182.885139v24.093809l82.521295 1.566097 1.084221-28.91257a10.601276 10.601276 0 0 0 0-2.770788 96.375235 96.375235 0 0 1 99.266492-93.363509h518.86017A97.820863 97.820863 0 0 1 927.312016 180.716696v660.411296a99.50743 99.50743 0 0 1-99.386961 99.386961z"
            data-spm-anchor-id="a313x.search_index.0.i2.42f43a81mwqDnF" />
    </svg>
    <svg v-else-if="name === 'split-cue'" :class="iconClass" :width="width" :height="height" :viewBox="viewBox"
        :xmlns="xmlns" :p-id="pId">
        <path
            d="M6.00001 0.750244C6.00001 0.33603 5.66422 0.000244141 5.25001 0.000244141C4.83579 0.000244141 4.50001 0.336031 4.50001 0.750244L4.50001 1.10975C4.50001 1.72344 4.75068 2.31051 5.19395 2.73492L7.26701 4.71982L4.82988 7.09988C4.42039 7.49039 4.31633 8.10894 4.59285 8.61168L4.60292 8.63001L6.88601 12.2829C6.96051 12.4021 7.00001 12.5399 7.00001 12.6804V13.2502C7.00001 13.6645 7.33579 14.0002 7.75001 14.0002C8.16422 14.0002 8.50001 13.6645 8.50001 13.2502L8.50001 12.6804C8.50001 12.2587 8.3815 11.8455 8.158 11.4879L6.00724 8.04672L8.47876 5.63307C8.8733 5.28191 8.97223 4.73973 8.81074 4.29147C8.7162 4.02904 8.53253 3.85435 8.46556 3.79066L8.45717 3.78266L6.23132 1.65147C6.08357 1.51 6.00001 1.31431 6.00001 1.10975L6.00001 0.750244Z" />
        <path
            d="M2.5 1.75024C2.08421 1.75024 1.75 2.08446 1.75 2.50024V11.5002C1.75 11.916 2.08421 12.2502 2.5 12.2502H5.25C5.66421 12.2502 6 12.586 6 13.0002C6 13.4145 5.66421 13.7502 5.25 13.7502H2.5C1.25579 13.7502 0.25 12.7445 0.25 11.5002V2.50024C0.25 1.25603 1.25579 0.250244 2.5 0.250244H3.11C3.52421 0.250244 3.86 0.586031 3.86 1.00024C3.86 1.41446 3.52421 1.75024 3.11 1.75024H2.5Z" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8.47296 2.23137C7.87783 1.41306 8.4474 0.250244 9.47952 0.250244H13.4995C14.7437 0.250244 15.7495 1.25603 15.7495 2.50024V11.5002C15.7495 12.7445 14.7437 13.7502 13.4995 13.7502H11.1795C10.7359 13.7502 10.302 13.5087 10.0856 13.0893L7.90236 9.06168L7.90098 9.05917C7.63468 8.57234 7.71835 7.95284 8.13705 7.5617L10.6534 4.96992L8.48264 2.24467L8.47296 2.23137ZM10.0065 1.75024L11.9884 4.23833L11.9998 4.25432C12.3627 4.7623 12.2997 5.45211 11.8588 5.88178L9.30861 8.50837L11.337 12.2502H13.4995C13.9153 12.2502 14.2495 11.916 14.2495 11.5002V2.50024C14.2495 2.08446 13.9153 1.75024 13.4995 1.75024H10.0065Z" />
    </svg>
    <svg v-else-if="name === 'icon-folder'" :class="iconClass" :width="width" :height="height" :viewBox="viewBox"
    :xmlns="xmlns" :p-id="pId"><path d="M928 444H820V330.4c0-17.7-14.3-32-32-32H473L355.7 186.2c-1.5-1.4-3.5-2.2-5.5-2.2H96c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h698c13 0 24.8-7.9 29.7-20l134-332c1.5-3.8 2.3-7.9 2.3-12 0-17.7-14.3-32-32-32zM136 256h188.5l119.6 114.4H748V444H238c-13 0-24.8 7.9-29.7 20L136 643.2V256z m635.3 512H159l103.3-256h612.4L771.3 768z" p-id="7453"></path></svg>
    <svg v-else-if="name === 'icon-script-file'" :class="iconClass" :width="width" :height="height" :viewBox="viewBox"
    :xmlns="xmlns" :p-id="pId"><path d="M876.8 288L684.8 64c-6.4-6.4-14.933333-10.666667-23.466667-10.666667H170.666667c-17.066667 0-32 14.933333-32 32v853.333334c0 17.066667 14.933333 32 32 32h682.666666c17.066667 0 32-14.933333 32-32V309.333333c0-8.533333-2.133333-14.933333-8.533333-21.333333z m-55.466667 618.666667h-618.666666v-789.333334h443.733333l174.933333 204.8v584.533334z" p-id="1506"></path><path d="M522.666667 362.666667v187.733333c-23.466667-17.066667-53.333333-27.733333-85.333334-27.733333-83.2 0-149.333333 66.133333-149.333333 149.333333s66.133333 149.333333 149.333333 149.333333 149.333333-66.133333 149.333334-149.333333V381.866667l110.933333-55.466667-27.733333-57.6-128 64c-12.8 6.4-19.2 17.066667-19.2 29.866667z m-85.333334 394.666666c-46.933333 0-85.333333-38.4-85.333333-85.333333s38.4-85.333333 85.333333-85.333333 85.333333 38.4 85.333334 85.333333-38.4 85.333333-85.333334 85.333333z" p-id="1507"></path></svg>
    <svg v-else-if="name === 'icon-script-files'" :class="iconClass" :width="width" :height="height" :viewBox="viewBox"
    :xmlns="xmlns" :p-id="pId"><path d="M640 427.52V512h-83.626667v174.08c-0.5632 20.48-9.642667 39.509333-27.306666 57.173333-16.503467 17.066667-36.4032 25.6-59.733334 25.6s-43.52-8.533333-60.586666-25.6c-16.503467-16.503467-24.746667-36.4032-24.746667-59.733333 0-23.313067 8.2432-43.52 24.746667-60.586667 17.066667-16.503467 37.256533-24.746667 60.586666-24.746666 15.36 0 29.576533 3.720533 42.666667 11.093333V427.52h128z" p-id="14090"></path><path d="M853.333333 955.733333H170.666667c-66.030933 0-102.4-36.369067-102.4-102.4V342.186667c0-66.030933 36.369067-102.4 102.4-102.4h682.666666c66.030933 0 102.4 36.369067 102.4 102.4V853.333333c0 66.030933-36.369067 102.4-102.4 102.4zM170.666667 273.92c-47.223467 0-68.266667 21.060267-68.266667 68.266667V853.333333c0 47.223467 21.0432 68.266667 68.266667 68.266667h682.666666c47.223467 0 68.266667-21.0432 68.266667-68.266667V342.186667c0-47.2064-21.0432-68.266667-68.266667-68.266667H170.666667z m685.226666-85.333333H170.666667a17.066667 17.066667 0 0 1 0-34.133334h685.226666a17.066667 17.066667 0 1 1 0 34.133334z m-85.333333-86.186667H256a17.066667 17.066667 0 0 1 0-34.133333h514.56a17.066667 17.066667 0 1 1 0 34.133333z" p-id="14091"></path></svg>
    <svg v-else-if="name === 'backend-exe'" :class="iconClass" :width="width" :height="height" :viewBox="viewBox"
    :xmlns="xmlns" :p-id="pId"><path d="M927.999436 531.028522a31.998984 31.998984 0 0 0-31.998984 31.998984c0 51.852948-10.147341 102.138098-30.163865 149.461048a385.47252 385.47252 0 0 1-204.377345 204.377345c-47.32295 20.016524-97.6081 30.163865-149.461048 30.163865s-102.138098-10.147341-149.461048-30.163865a385.47252 385.47252 0 0 1-204.377345-204.377345c-20.016524-47.32295-30.163865-97.6081-30.163865-149.461048s10.147341-102.138098 30.163865-149.461048a385.47252 385.47252 0 0 1 204.377345-204.377345c47.32295-20.016524 97.6081-30.163865 149.461048-30.163865a387.379888 387.379888 0 0 1 59.193424 4.533611l-56.538282 22.035878A31.998984 31.998984 0 1 0 537.892156 265.232491l137.041483-53.402685a31.998984 31.998984 0 0 0 18.195855-41.434674L639.723197 33.357261a31.998984 31.998984 0 1 0-59.630529 23.23882l26.695923 68.502679a449.969005 449.969005 0 0 0-94.786785-10.060642c-60.465003 0-119.138236 11.8488-174.390489 35.217667a449.214005 449.214005 0 0 0-238.388457 238.388457c-23.361643 55.252253-35.22128 113.925486-35.22128 174.390489s11.8488 119.138236 35.217668 174.390489a449.214005 449.214005 0 0 0 238.388457 238.388457c55.252253 23.368867 113.925486 35.217667 174.390489 35.217667s119.138236-11.8488 174.390489-35.217667A449.210393 449.210393 0 0 0 924.784365 737.42522c23.368867-55.270316 35.217667-113.925486 35.217667-174.390489a31.998984 31.998984 0 0 0-32.002596-32.006209z" fill="" p-id="7761"></path></svg>
    <svg v-else-if="name === 'cloud-down'" :class="iconClass" :width="width" :height="height" :viewBox="viewBox"
    :xmlns="xmlns" :p-id="pId"><path d="M512 729.6V469.333333h42.666667v260.266667l98.133333-98.133333 29.866667 29.866666-106.666667 106.666667h170.666667c85.333333-8.533333 149.333333-81.066667 149.333333-170.666667 0-93.866667-76.8-170.666667-170.666667-170.666666h-4.266666c-21.333333-98.133333-106.666667-170.666667-209.066667-170.666667-119.466667 0-213.333333 93.866667-213.333333 213.333333-72.533333 8.533333-128 72.533333-128 149.333334C170.666667 699.733333 238.933333 768 320 768h170.666667L384 661.333333l29.866667-29.866666 98.133333 98.133333z m64 38.4l-42.666667 42.666667-42.666666-42.666667h-42.666667v42.666667h-128C213.333333 810.666667 128 725.333333 128 618.666667c0-85.333333 55.466667-157.866667 128-183.466667C273.066667 311.466667 379.733333 213.333333 512 213.333333c110.933333 0 209.066667 72.533333 243.2 170.666667 102.4 12.8 183.466667 102.4 183.466667 213.333333s-85.333333 200.533333-192 213.333334h-128v-42.666667h-42.666667z"></path></svg>
</template>

<script>
export default {
    name: 'SvgIcon',
    props: {
        // 图标名称
        name: {
            type: String,
            required: true
        },
        // 图标类名
        iconClass: {
            type: String,
            default: ''
        },
        // 宽度
        width: {
            type: [String, Number],
            default: '32'
        },
        // 高度
        height: {
            type: [String, Number],
            default: '32'
        },
        // viewBox
        viewBox: {
            type: String,
            default: '0 0 1024 1024'
        },
        // xmlns
        xmlns: {
            type: String,
            default: 'http://www.w3.org/2000/svg'
        },
        // p-id
        pId: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>
.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
</style>