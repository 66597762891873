<template>
    <div class="flex flex-col md:flex-row h-screen-header relative">
        <div class="md:w-1/2 flex flex-col items-center mt-[6%] z-[2] !hidden md:!flex">
            <div class="mb-2">
                <div class="text-2xl font-medium">
                    {{song.title}}
                </div>
                <div class="flex text-gray-400">
                    <div>
                        艺术家：{{song.artist}}
                    </div>
                    <div class="ml-2">
                        专辑：{{song.album}}
                    </div>
                </div>
            </div>
            <div class="xl:w-1/2 2xl:w-[40%] rounded-xl shadow-xl stack">
                <img :src="songImageWall" style="object-fit: cover;" class="rounded-xl">
                <img :src="songImageWall" style="object-fit: cover;" class="rounded-xl">
                <img :src="songImageWall" style="object-fit: cover;" class="rounded-xl">
            </div>
        </div>
        <div class="md:w-1/3 overflow-y-auto mt-[6%] md:mt-[6%] mb-[12%] lyrics z-[3]">
            <div class="h-full relative">
                <ul ref="lyricUL" class="absolute w-full transition-transform duration-300 ease-out">
                    <li v-for="(item, i) in lyricsObjArr"
                        :class="[lyricIndex === i ? 'text-2xl text-purple-600' : 'text-base text-gray-400', 'text-center', 'mb-2', 'cursor-pointer']"
                        :key="item.uid" :data-index="i" ref="lyric" @click="scrollToLyric(i)">{{item.lyric}}</li>
                </ul>
            </div>
        </div>
        <div class="backdrop absolute" :style="{ 'background-image': 'linear-gradient(to bottom, transparent, black),' + backgroundImage }"></div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'playing-list',
        data() {
            return {
                id: this.$route.params.id,
                song: {},
                lyrics: '',
                lyricIndex: 0,
                lyricsObjArr: [],
                fontSizeMap: {
                    small: { base: 170, step: 25 },    // 小字号
                    medium: { base: 170, step: 30 },   // 中字号
                    large: { base: 200, step: 40 },    // 大字号
                    xlarge: { base: 230, step: 45 }    // 特大字号
                },
                cachedFontSize: null
            }
        },
        computed: {
            ...mapGetters(['currentTime', 'trackId', 'duration']),
            backgroundImage() {
                return `url('${this.song.image || '/static/dist/img/album_null.jpg'}')`
            },
            songImageWall() {
                if (this.song.image) {
                    return this.song.image.replace('al-', 'alw-')
                } else {
                    return this.song.image
                }
            }
        },
        watch: {
            currentTime: {
                handler() {
                    // 匹配歌词
                    for (let i = 0; i < this.lyricsObjArr.length; i++) {
                        if (this.currentTime > (parseInt(this.lyricsObjArr[i].time))) {
                            const index = this.$refs.lyric[i].dataset.index
                            if (i === parseInt(index)) {
                                this.lyricIndex = i
                                this.scrollLyrics(i)
                            }
                        }
                    }
                },
                immediate: true
            },
            trackId() {
                if (this.$route.params.id !== this.trackId) {
                    this.$api.subsonic.getSong(this.trackId).then((res) => {
                        this.song = res
                    })
                    this.$api.subsonic.getLyrics(this.trackId).then((res) => {
                        this.lyricsObjArr = this.formatLyrics(res.value)
                    })
                }
            }
        },
        async created() {
            this.song = await this.$api.subsonic.getSong(this.id)
            this.$api.subsonic.getLyrics(this.id).then((res) => {
                this.lyricsObjArr = this.formatLyrics(res.value)
            })
        },
        mounted() {
            // 初始化时缓存字体大小
            this.cachedFontSize = this.detectFontSize();
        },
        methods: {
            formatLyrics(lyrics) {
                if (!lyrics) return []
                const regNewLine = /\n/
                const lineArr = lyrics.split(regNewLine) // 每行歌词的数组

                const lyricsObjArr = []
                lineArr.forEach(item => {
                    if (item === '') return
                    const spiltList = item.split(']')
                    const spiltListLength = spiltList.length
                    const content = spiltList[spiltListLength-1].trim()

                    const timeArray = spiltList.slice(0, spiltListLength-1)
                    timeArray.forEach(dt => {
                        const obj = {}
                        obj.lyric = content === '' ? '' : content
                        obj.time = this.formatLyricTime(dt.slice(1))
                        obj.uid = Math.random().toString().slice(-6)
                        if (obj.lyric === '') {
                            console.log('')
                        } else {
                            lyricsObjArr.push(obj)
                        }
                    })
                })
                return lyricsObjArr.sort((a,b) => a.time - b.time)
            },
            formatLyricTime(item) { // 格式化歌词的时间 转换成 sss:ms
                if (item.indexOf('.') === -1) {
                    const timeParts = item.split(':')
                    const hours = parseInt(timeParts[0])
                    const minutes = parseInt(timeParts[1])
                    const seconds = parseInt(timeParts[2])
                    const totalTime = hours * 60 * 60 + minutes * 60 + seconds
                    return Number(totalTime)
                } else {
                    const regTime = /\d{2}:\d{2}.\d{2,3}/
                    const isTime = item.match(regTime)
                    const time = isTime ? isTime[0] : '00:00.00'
                    const regMin = /.*:/
                    const regSec = /:.*\./
                    const regMs = /\./

                    const min = parseInt(time.match(regMin)[0].slice(0, 2))
                    let sec = parseInt(time.match(regSec)[0].slice(1, 3))
                    const ms = time.slice(time.match(regMs).index + 1, time.match(regMs).index + 3)
                    if (min !== 0) {
                        sec += min * 60
                    }
                    return Number(sec + '.' + ms)
                }
            },
            detectFontSize() {
                // 使用缓存的字体大小，除非强制刷新
                if (this.cachedFontSize) return this.cachedFontSize;
                
                const fontSize = window.getComputedStyle(document.documentElement).fontSize;
                const size = parseInt(fontSize);
                
                if (size <= 14) return 'small';
                if (size <= 16) return 'medium';
                if (size <= 20) return 'large';
                return 'xlarge';
            },
            scrollLyrics(index) {
                const fontSizeType = this.detectFontSize();
                const { base, step } = this.fontSizeMap[fontSizeType];
                
                if (this.$refs.lyricUL) {
                    const translateY = base - (step * (index + 1));
                    this.$refs.lyricUL.style.transform = `translateY(${translateY}px)`;
                }
            },
            scrollToLyric(i) {
            const currentTime = this.lyricsObjArr[i].time
            const seek = currentTime/ this.duration
            this.$store.dispatch('seek', seek)
        },
        }
    }
</script>

<style scoped>
.lyrics::-webkit-scrollbar {
    width: 0;
}
.backdrop {
    position: absolute;
    z-index: 1;
    width: 95%;
    top: 3%;
    left: 3%;
    height: 34%;
    transform: scale(1.045);
    filter: blur(8px);
    opacity: 0.1;
    background-size: max(100%, 1000px) auto;
    background-position: center center;
    background-repeat: no-repeat;
}
</style>
